import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';

import useCandidato from '../../context/CandidatoContext';
import ModalSteps from '../ModalSteps';
import { Content, Title, Form, Input, Button, Select, Row, Label, Table } from './styles';
import {Gray, Bordor, formatDate, maior18Anos}  from "../../util/constants";

const DadosFamiliares = ({ disabledButton }) => {
  const { candidato, updateCandidato } = useCandidato();
  const [showModal, setShowModal] = useState(false);
  const [listGroupFamiliar, setListGroupFamiliar] = useState([])
  const [grupoFamiliar, setGrupoFamiliar] = useState({})
  const [isValid, setIsValid] = useState(false)
  
  useEffect(() => {
    setListGroupFamiliar(candidato.receitas_attributes.filter( (receita) => maior18Anos(receita.data_nascimento) ))
  }, [candidato])

  useEffect(() => {
    disabledButton(!validAllGrupoFamiliar())
  }, [isValid, listGroupFamiliar])

  function validAllGrupoFamiliar() {
    let valid = false;
    let i = 0;
    while (i < listGroupFamiliar.length) {
      valid = validaCampos(listGroupFamiliar[i])
      if (valid) {
        setIsValid(true)
      } else {
        setIsValid(false)
        break
      }
      i += 1;
   }
    return valid
  } 

  function validGroupFamiliar() {
    if (validaCampos(grupoFamiliar)) {
      saveGrupoFamiliar()
    } else {
      alert('Por favor preencher todos os campos!')
      setShowModal(true);
    }
  }

  function validaCampos(item) {
    let result = true

    if  (
           item.nome === ''             || item.nome === null
        || item.cpf  === ''             || item.cpf === null
        || item.rg   === ''             || item.rg === null
        || item.nacionalidade === ''    || item.nacionalidade === null
        || item.estado_civil === ''     || item.estado_civil === null
        || item.telefone_contato === '' || item.telefone_contato === null
        || item.email === ''            || item.email === null
        || item.cep === ''              || item.cep === null
        //|| item.tipo_logradouro === '' 
        || item.endereco === ''         || item.endereco === null
        || item.numero === ''           || item.numero === null
        || item.bairro === ''           || item.bairro === null
        || item.cidade === ''           || item.cidade === null
        //|| item.complemento === '' 
        || item.uf === ''               || item.uf === null
        || item.pais === ''             || item.pais === null 
        )
      {
      result = false
      }

   return result
  }

  function saveGrupoFamiliar() {
    if (candidato.receitas_attributes.some( (item) => item.uuid === grupoFamiliar.uuid) ) {
      const atualizaReceita = candidato.receitas_attributes.map( (item) => item.uuid === grupoFamiliar.uuid ? grupoFamiliar : item )
      updateCandidato('receitas_attributes', atualizaReceita)
    } 
    limparCampos();
    setListGroupFamiliar(candidato.receitas_attributes.filter( (receita) => receita.contribue_renda === true ))
    setShowModal(false);
  }

  function editarGrupoFamiliar(item) {
    setGrupoFamiliar({
      id: item.id,
      uuid: item.uuid,
      nome: item.nome, 
      parentesco: item.parentesco, 
      contribue_renda: item.contribue_renda, 
      responsavel_financeiro: item.responsavel_financeiro, 
      atividade_principal: item.atividade_principal,
      valor: item.valor,
      cpf: item.cpf, //? item.cpf : "628.606.313-72", 
      rg: item.rg,   //? item.rg : "200099618002",
      data_nascimento: item.data_nascimento, //? item.data_nascimento : "22/09/1980", 
      nacionalidade: item.nacionalidade, //? item.nacionalidade : "Brasileira", 
      email: item.email, //? item.email : "mariasilva@gmail.com", 
      estado_civil: item.estado_civil, //? item.estado_civil : "Solteiro", 
      telefone_contato: item.telefone_contato, // ? item.telefone_contato : "85 999836742",
      cep: item.cep, // ? item.cep : "60742-300",
      tipo_logradouro: item.tipo_logradouro, // ? item.tipo_logradouro : "",
      endereco: item.endereco, // ? item.endereco : "Rua Libania",
      numero: item.numero, // ? item.numero : "1204",
      bairro: item.bairro, // ? item.bairro : "Serrinha",
      cidade: item.cidade, // ? item.cidade : "Fortaleza",
      complemento: item.complemento, // ? item.complemento : "proximo a pague menos",
      uf: item.uf, // ? item.uf : "CE",
      pais: item.pais, // ? item.pais : "Brasil"
    });
    setShowModal(true);
  }
  
  function limparCampos() {
    setGrupoFamiliar({
      nome: '',
      parentesco: '', 
      contribue_renda: '',
      responsavel_financeiro:'',
      atividade_principal: '',
      valor: '',
      cpf: '',
      rg: '',
      data_nascimento: '',
      nacionalidade:'', 
      email: '',
      estado_civil: '',
      telefone_contato:'',
      cep: '',
      tipo_logradouro: '',
      endereco:'',
      numero: '',
      bairro: '',
      cidade: '',
      complemento:'',
      uf: '',
      pais:''
    });
    setShowModal(false);
  }

  return (
    <>

      <Title>Informar dados complementares do familiar</Title>

      <ModalSteps show={showModal} onClose={() => setShowModal(false)}>
      <Content >
        <Row>
          <div style={{
            width: '100%',
            marginBottom: '16px',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: Bordor,
          }}
          >
            <p>
              ATENÇÃO: É OBRIGATÓRIO O PREENCHIMENTO DE TODOS OS CAMPOS PARA PROSSEGUIR
            </p> 
          </div>
        </Row>
        <Form action="">
        <Row>
          <div style={{ flex: 2 }}>
            <Label>Nome Completo</Label>
            <Input
              id={`DadosFamiliarNomeCompleto`}
              placeholder="Nome Completo"
              value={grupoFamiliar.nome}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, nome: event.target.value})}
              disabled={true}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>CPF</Label>
            <Input
              id={`DadosFamiliarCPF`}
              placeholder="CPF"
              value={grupoFamiliar.cpf}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, cpf: event.target.value})}
              mask='999.999.999-99'
             />
          </div>

          

          {/* <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>Data Nascimento</Label>
            <Input
              id={`DadosFamiliarDataNascimento`}
              placeholder="00/00/0000"
              value={grupoFamiliar.data_nascimento}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, data_nascimento: event.target.value})}
             />
          </div> */}

        </Row>

        <Row>

          <div style={{ flex: 1 }}>
            <Label>RG</Label>
            <Input
              id={`DadosFamiliarRG`}
              placeholder="RG"
              value={grupoFamiliar.rg}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, rg: event.target.value})}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>Nacionalidade</Label>
            <Input
              id={`DadosFamiliarNascionalidade`}
              placeholder="Nacionalidade"
              value={grupoFamiliar.nacionalidade}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, nacionalidade: event.target.value})}
             />
          </div>
          
          <div style={{ flex: 1, marginLeft: '16px'  }}>
            <Label>Telefone de Contato</Label>
            <Input
              id={`DadosFamiliarTelefone`}
              placeholder="(00)0000-0000"
              value={grupoFamiliar.telefone_contato}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, telefone_contato: event.target.value})}
              mask="(99)9.9999.9999"
             />
          </div>

        </Row>

        <Row>

          <div style={{ flex: 2 }}>
            <Label>e-mail</Label>
            <Input
              id={`DadosFamiliarEmail`}
              placeholder="joao@admin.org"
              value={grupoFamiliar.email}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, email: event.target.value})}
             />
          </div>

          <div style={{ flex: 1.3, marginLeft: '16px' }}>
            <Label>Estado Civil</Label>
            <Select value={grupoFamiliar.estado_civil} onChange={(event) => setGrupoFamiliar({...grupoFamiliar, estado_civil: event.target.value})}>
                <option selected value="">Selecione uma opção</option>
                <option value="casado">Casado</option>
                <option value="solteiro">Solteiro</option>
                <option value="desquitado"> Desquitado</option>
                <option value="divorciado">Divorciado</option>
                <option value="viuvo">Viuva (o)</option>
              </Select>
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>CEP</Label>
            <Input
              id={`DadosFamiliarCEP`}
              placeholder="000000-000"
              value={grupoFamiliar.cep}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, cep: event.target.value})}
              mask="99.999-999"
             />
          </div>


        </Row>
      
        <Row>
          <div style={{ flex: 2 }}>
            <Label>Endereço</Label>
            <Input
              id={`DadosFamiliarEndereco`}
              placeholder=""
              value={grupoFamiliar.endereco}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, endereco: event.target.value})}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>Número</Label>
            <Input
              id={`DadosFamiliarNumero`}
              placeholder=""
              value={grupoFamiliar.numero}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, numero: event.target.value})}
             />
          </div>

        </Row>

        <Row>
          <div style={{ flex: 2 }}>
            <Label>Complemento</Label>
            <Input
              id={`DadosFamiliarComplemento`}
              placeholder=""
              value={grupoFamiliar.complemento}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, complemento: event.target.value})}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>Bairro</Label>
            <Input
              id={`DadosFamiliarBairro`}
              placeholder=""
              value={grupoFamiliar.bairro}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, bairro: event.target.value})}
             />
          </div>

        </Row>

        <Row>
          <div style={{ flex: 1 }}>
            <Label>Cidade</Label>
            <Input
              id={`DadosFamiliarCidade`}
              placeholder=""
              value={grupoFamiliar.cidade}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, cidade: event.target.value})}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>Estado</Label>
            <Input
              id={`DadosFamiliarEstado`}
              placeholder=""
              value={grupoFamiliar.uf}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, uf: event.target.value})}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>País</Label>
            <Input
              id={`DadosFamiliarPais`}
              placeholder=""
              value={grupoFamiliar.pais}
              onChange={(event) => setGrupoFamiliar({...grupoFamiliar, pais: event.target.value})}
             />
          </div>

        </Row>
        </Form>

        <div style={{display: 'flex', width: '100%', marginTop: '8px'}}>
          <Button 
            onClick={limparCampos}
            >
            Cancelar
          </Button>
          
          <Button
            onClick={validGroupFamiliar}
            >
            Confirmar
          </Button>
         </div>


      </Content>
          
      </ModalSteps>

      <Table>
        <thead>
          <tr>
            <th style={{textAlign: 'left'}}>Nome</th>
            <th>Parentesco</th>
            <th>CPF</th>
            <th style={{textAlign: 'left'}}>RG </th>
            <th>Data Nascimento</th>
            <th></th>
          </tr>
        </thead>

        <tbody>

          { 
            listGroupFamiliar.length > 0 ? (
              listGroupFamiliar.map((item, index) => (
              <tr key={index}>
                <td className="alignLeft">{item.nome}</td>
                <td className="alignCenter">{item.parentesco}</td>
                <td className="alignCenter">{item.cpf}</td>
                <td className="alignLeft">{item.rg}</td>
                <td className="alignCenter">{formatDate(item.data_nascimento)}</td>
                <td> 
                  <button 
                    title=""
                    style={{ width: "35px", height: "35px", border: "none", background: Gray }}
                    onClick={() => editarGrupoFamiliar(item)}
                  >
                    <Icon path={mdiPencil} size="16px" color="white"/>
                  </button>
                </td>
              </tr> 
            )) 

            ) : (
              <tr>
                <td className="alignLeft"></td>
                <td className="alignCenter"></td>
                <td className="alignCenter"></td>
                <td className="alignLeft"></td>
                <td className="alignCenter"></td>
                <td className="alignRight"></td>
              </tr>               
            )
          
          }

        </tbody>
      </Table>  

      {/* {candidato &&
      <Content >
        <Row>
          <div style={{ flex: "0 0 248px" }}>
            <Label>Pai ou Mae</Label>
            <Input
              id={`DadosFamiliarPaiOuMae`}
              placeholder="Pai ou Mae"
              value={dadosFamiliares.pai_ou_mae }
              onChange={(event) => setAll({ ...dadosFamiliares, pai_ou_mae: event.target.value })}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>Nome Completo</Label>
            <Input
              id={`DadosFamiliarNomeCompleto`}
              placeholder="Nome Completo"
              value={dadosFamiliares.nome }
              onChange={(event) => setAll({ ...dadosFamiliares, nome: event.target.value })}
             />
          </div>

        </Row>

        <Row>
          <div style={{ flex: "0 0 248px" }}>
            <Label>CPF</Label>
            <Input
              id={`DadosFamiliarCPF`}
              placeholder="CPF"
              value={dadosFamiliares.cpf }
              onChange={(event) => setAll({ ...dadosFamiliares, cpf: event.target.value })}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>RG</Label>
            <Input
              id={`DadosFamiliarRG`}
              placeholder="RG"
              value={dadosFamiliares.rg }
              onChange={(event) => setAll({ ...dadosFamiliares, rg: event.target.value })}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>Nacionalidade</Label>
            <Input
              id={`DadosFamiliarNascionalidade`}
              placeholder="Nacionalidade"
              value={dadosFamiliares.nacionalidade }
              onChange={(event) => setAll({ ...dadosFamiliares, nacionalidade: event.target.value })}
             />
          </div>
        </Row>

        <Row>
          <div style={{ flex: "0 0 248px" }}>
            <Label>Telefone Contato</Label>
            <Input
              id={`DadosFamiliarTelefone`}
              placeholder="(00)0000-0000"
              value={dadosFamiliares.telefone_contato }
              onChange={(event) => setAll({ ...dadosFamiliares, telefone_contato: event.target.value })}
             />
          </div>

          <div style={{ flex: 1, marginLeft: '16px' }}>
            <Label>e-mail</Label>
            <Input
              id={`DadosFamiliarEmail`}
              placeholder="joao@admin.org"
              value={dadosFamiliares.email }
              onChange={(event) => setAll({ ...dadosFamiliares, email: event.target.value })}
             />
          </div>
        </Row>

        <Row>
          <div style={{ flex: "0 0 248px" }}>
            <Label>CEP</Label>
            <Input
              id={`DadosFamiliarCEP`}
              placeholder="60000000"
              value={dadosFamiliares.cep }
              onChange={(event) => setAll({ ...dadosFamiliares, cep: event.target.value })}
             />
          </div>

          <div style={{ flex: 3, marginLeft: '16px' }}>
            <Label>Endereço</Label>
            <Input
              id={`DadosFamiliarEndereco`}
              placeholder="Endereco"
              value={dadosFamiliares.endereco }
              onChange={(event) => setAll({ ...dadosFamiliares, endereco: event.target.value })}
             />
          </div>

          <div style={{ flex: 0.5, marginLeft: '16px' }}>
            <Label>No</Label>
            <Input
              id={`DadosFamiliarNumero`}
              placeholder="0000"
              value={dadosFamiliares.numero }
              onChange={(event) => setAll({ ...dadosFamiliares, numero: event.target.value })}
             />
          </div>

        </Row>

        <Row>
          <div style={{ flex: "0 0 248px" }}>
            <Label>Bairro</Label>
            <Input
              id={`DadosFamiliarBairro`}
              placeholder="Bairro"
              value={dadosFamiliares.bairro }
              onChange={(event) => setAll({ ...dadosFamiliares, bairro: event.target.value })}
             />
          </div>

          <div style={{ flex: 2.5, marginLeft: "16px"}}>
            <Label>Cidade</Label>
            <Input
              id={`DadosFamiliarCidade`}
              placeholder="Cidade"
              value={dadosFamiliares.cidade }
              onChange={(event) => setAll({ ...dadosFamiliares, cidade: event.target.value })}
             />
          </div>

          <div style={{ flex: 0.5, marginLeft: '16px' }}>
            <Label>UF</Label>
            <Input
              id={`DadosFamiliarUF`}
              placeholder="Estado"
              value={dadosFamiliares.uf }
              onChange={(event) => setAll({ ...dadosFamiliares, uf: event.target.value })}
             />
          </div>

          <div style={{ flex: 0.5, marginLeft: '16px' }}>
            <Label>País</Label>
            <Input
              id={`DadosFamiliarPais`}
              placeholder="Pais"
              value={dadosFamiliares.pais }
              onChange={(event) => setAll({ ...dadosFamiliares, pais: event.target.value })}
             />
          </div>

        </Row>

      </Content >
      } */}
    </>
  )
}

export default DadosFamiliares;
