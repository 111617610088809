// import format from 'date-fns/format';

export function formatDate(date) {
  const dateStr = date.split('-')
  const dateFormatted = new Date(dateStr[0], parseInt(dateStr[1]) - 1, dateStr[2]);
  const day = dateFormatted.getUTCDate() > 9 ? dateFormatted.getUTCDate() : `0${dateFormatted.getUTCDate()}`;
  const month = dateFormatted.getUTCMonth() + 1 > 9 ? dateFormatted.getUTCMonth() + 1 : `0${dateFormatted.getUTCMonth() + 1}`;
  const year = dateFormatted.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

// export function formatDate(date) {
//   const dateStr = date.split('/')
//   const newDate = format(new Date(dateStr[0], parseInt(dateStr[1]) - 1, dateStr[2]), 'dd/MM/yyyy')
//   return newDate
// };

// const formatDate = (date: string) : string => {
// export function formatDate(date) {
//   const dateStr = date.split('/')
//   const dateFormatted = new Date(date);
//   const day = dateFormatted.getUTCDate() > 9 ? dateFormatted.getUTCDate() : `0${dateFormatted.getUTCDate()}`;
//   const month = dateFormatted.getUTCMonth() + 1 > 9 ? dateFormatted.getUTCMonth() + 1 : `0${dateFormatted.getUTCMonth() + 1}`;
//   const year = dateFormatted.getUTCFullYear();
  
//   return `${day}/${month}/${year}`;
// };

// export default formatDate;

export function maior18Anos(data) {
  const dateStr = data.split('-')
  const idade = calculaIdade(parseInt(dateStr[0]), parseInt(dateStr[1]), parseInt(dateStr[2]))
  return idade > 18 ? true : false
}

export function calculaIdade(ano, mes, dia) {
  let quantos_anos = 0
  const d = new Date(),
  ano_atual = d.getFullYear(),
  mes_atual = d.getMonth() + 1,
  dia_atual = d.getDate(),

  ano_aniversario = +ano,
  mes_aniversario = +mes,
  dia_aniversario = +dia

  quantos_anos = ano_atual - ano_aniversario;

  if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
    quantos_anos--;
  }

  return quantos_anos < 0 ? 0 : quantos_anos;
}  

export function currencyConfig()  {
  return (
    {
      locale: "pt-BR",
      formats: {
        number: {
          BRL: {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    }
  )
};  

export function currencyFormatter(value) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);

  return `${amount}`;
}



export const Red = '#e6e4e1';  //"#960631";
export const Blue = "#1A5A98";
export const Gray = "#4D5864";
export const Green = "#24AA7B";
export const Bordor = "#AA192D";
export const TextColor = "#222222";
export const TextButton = "#FFFFFF";
