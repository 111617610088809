import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
// import ptBR from 'date-fns/locale/pt-BR';

import { Content, Label, Text, Strong, DataCol, InputChechkBox, Logo } from './styles';
import useCandidato from '../../context/CandidatoContext';

const ConfirmSteps = ( { disabledButton } ) => {
  const [checked, setChecked] = useState(false)
  const { candidato, updateCandidatoEAtributosAninhados } = useCandidato();
  const dateStr = candidato.data_nascimento.split('-')
  const dataNascimento = format(new Date(dateStr[0], parseInt(dateStr[1]) - 1, dateStr[2]), 'dd/MM/yyyy')
 
  function enderecoCompleto() {
    return candidato.endereco + ', ' + candidato.numero + ', ' + candidato.bairro + ', ' + candidato.municipio + ' / ' + candidato.uf + ' - ' + candidato.cep
  }

  function handleAutorizacao(checked) {
    setChecked(checked)
    disabledButton(!checked)
    //updateCandidato('autorizacao', checked)
    updateCandidatoEAtributosAninhados({
      'status': 'iniciado',
      'autorizacao': checked
    })
  }

  useEffect(() => {
    setChecked(candidato.autorizacao)
  }, [])

  useEffect(() => {
    disabledButton(!checked)
  }, [checked])

  return (
    <>
      <Content >
        <DataCol >
          <Strong>Nome:</Strong>
          <Strong>E-mail:</Strong>
          <Strong>Número Fixo:</Strong>
          <Strong>Número Celular:</Strong>
          <Strong>RG:</Strong>
          <Strong>CPF:</Strong>
          <Strong>Data Nascimento:</Strong>
          <Strong>Sexo:</Strong>
          <Strong>Estado Civil:</Strong>
          <Strong>Endereço:</Strong>
        </DataCol>

        {candidato &&
          <>
            <DataCol >
              <Text>{candidato.nome}</Text>
              <Text>{candidato.email}</Text>
              <Text>{candidato.numero_telefone || "."}</Text>
              <Text>{candidato.numero_celular  || "."}</Text>
              <Text>{candidato.rg}</Text>
              <Text>{candidato.cpf}</Text>
              <Text>{dataNascimento}</Text>
              <Text>{candidato.sexo}</Text>
              <Text>{candidato.estado_civil}</Text>
              <Text>{enderecoCompleto()}</Text>
            </DataCol>

            <div>
              { 
                
              }
            </div>
          </>
        }

      </Content>

      <div style={{marginLeft: '120px', marginRight: '60px'}}>
        <Strong>
          <InputChechkBox
            type="checkbox"
            id="confirm_polices"
            name="confirm_polices"
            checked={checked}
            onChange={(event) => handleAutorizacao(event.target.checked) }
          />

          <Label htmlFor="confirm_polices">
            “Autorizo a ESPM a utilizar os dados aqui compartilhados para fins de avaliação de minha solicitação de Bolsa ESPM.  
            A política de privacidade da ESPM está disponível na íntegra no endereço:
            <a
              href="https://www.espm.br/politica-de-privacidade/"
              rel="noreferrer"
              target="_blank">
              https://www.espm.br/politica-de-privacidade/
            </a>"
          </Label>
        </Strong>
      </div>

    </>
  )
}

export default ConfirmSteps;
