import styled from "styled-components";
import {TextColor} from "../../util/constants"

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`

export const InputChechkBox = styled.input`
  padding: 10px;
  transform: scale(1.5);
`;

export const Label = styled.label`
 margin-left: 8px;
 line-height: 1.5;
`;

export const Strong = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 700;

`;

export const Text = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 400;
`; 


export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-of-type{
    align-items: flex-end;
    margin-right: 10px;
    }
`;

