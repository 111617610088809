import styled from "styled-components";
import InputMask from 'react-input-mask';
import { TextColor, Bordor } from '../../util/constants.js'

export const Row = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  
`;

export const Col = styled.div`
  flex: 1;
  height: 100vh;
  padding-left: 64px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &:first-of-type{
    flex: 0 0 450px;
  }
  
`;

export const Logo = styled.div`
  width: 97px;
  height: 37px;
  background: ${props => `url(${props.bg})` };
  background-size: cover;
  background-position: center;

  margin-bottom: 64px;
`;

export const Image = styled.div`
  width: 100%;
  height: 100vh;
  background: ${props => `url(${props.bg})` };
  background-size: cover;
  background-position: center;
`;

export const Title = styled.p`
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 32px;
  line-height: 33px;
`;

export const Label = styled.label`
  color: ${TextColor};
  margin-bottom: 16px;
  
  font-size: 18px;
  font-weight: 600;
`;

export const Input = styled(InputMask)`
  -webkit-appearance: none;
  appearance: none;

  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;

  width: 100%;
`;

export const Button = styled.button`
  -webkit-appearance: none;
  appearance: none;

  background: ${Bordor};
  color: white;

  border-radius: 5px;
  border: none;

  height: 50px;
  width: 100%;

  text-align: center;
  margin-bottom: 24px;
`;

export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-of-type{
    align-items: flex-end;
    margin-right: 10px;
    }
`;

export const Text = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 400;
`;

export const Strong = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 700;

`;

export const Form = styled.form`
  width: 380px;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
`;

export const ErrorMessage = styled.span`
  margin-bottom: 16px;
  padding: 0 8px;

  background-color: white;
  color: ${Bordor};
  border-radius: 5px;
  font-weight: 600;

  
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  
`