import { CandidatoProvider } from './context/CandidatoContext';
import Routes from './routes';

function App() {
  return (
    <div>
      <CandidatoProvider>
        <Routes/>
      </CandidatoProvider>
    </div>
  );
}

export default App;
