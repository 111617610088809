import styled from "styled-components";
import { TextColor } from "../../util/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  
  > div {
    span {
      flex: 0 0 120px;
      margin-right: 16px;
    }
  }
`;

export const Select = styled.select`
  background: #fff; 
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  /* padding-right: 10px; */
  margin-bottom: 16px; 
  width: 100%;

  
`;

export const Label = styled.label`
  display: block;
  color: ${TextColor};
  margin-bottom: 8px;
  font-weight: 600;
  > span + div {
    margin-top: 8px;
  }
`;

export const Input = styled.input`
  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;

  width: 100%;
`;

export const Strong = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;
