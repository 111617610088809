import styled from "styled-components";
import { TextColor } from "../../util/constants";
import IntlCurrencyInput from "react-intl-currency-input";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  
  > div {
    span {
      flex: 0 0 120px;
      margin-right: 16px;
    }

    input {
      flex: 3;
      &:first-of-type {
        flex: 1;
        margin-right: 16px;
      }

    }
  }
`;

export const Row = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  
`;

export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-of-type{
    align-items: flex-end;
    margin-right: 10px;
    }
`;

export const Strong = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 700;

`;

export const Input = styled.input`
-webkit-appearance: none;
appearance: none;

background: white;
border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;
`;

export const CurrencyInput = styled(IntlCurrencyInput)`
  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;
  width: 100%;
`;

export const Select = styled.select`
-webkit-appearance: none;
appearance: none;

background: white;
border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;
`;

export const Label = styled.label`
  display: block;
  color: white;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const Title = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

