import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Content, Button , Title} from './styles';
import useCandidato from '../../context/CandidatoContext';
import {Gray, Bordor, currencyFormatter}  from "../../util/constants.js";
// import winner from '../../assets/winners.svg';

import TextoCandidatoNaoAprovado from '../../components/TextoCandidatoNaoAprovado';

const CandidatoNaoAprovado = ( { disabledButton } ) => {
  const history = useHistory();
  const { 
    candidato, 
    candidatoAprovado, 
    localeCandidatoAprovado, 
    putCandidatoFinalStatus } = useCandidato();
  
  useEffect(() => {
    localeCandidatoAprovado()
  }, [])

  useEffect(() => {
    disabledButton(!candidatoAprovado.fiador_aprovado.status)
  }, [candidatoAprovado])

  // function handleHome() {
  //   localStorage.removeItem('candidato')
  //   history.push(`/`)
  //   window.location.reload();
  // }

  function handleClose() {
    putCandidatoFinalStatus('Não elegível');   
    history.push(`/`);
    localStorage.removeItem('candidato');
    window.location.reload();
   }

 
  return (
    <>
      <Content >

        {
          candidatoAprovado.fiador_aprovado.status ? (
            <> 
              <Title>Conforme informações declaradas, a renda do fiador está de acordo com o regulamento deste benefício.</Title>
              {/* <img src={winner} alt="Winner" style={{ width: '50%', margin: '32px auto' }}/>  */}
            </>
            ) : (
            <>
              <Title>Conforme informações declaradas, o fiador não está de acordo com o regulamento deste benefício.</Title>

              <p>O valor referente ao respectivo percentual não poderá representar mais que 30% da renda mensal do fiador</p>

              <p>Renda Mensal: {currencyFormatter(Number(candidato.fiadores_attributes[0].renda_bruta))}</p>
              <p>Mensalidade: 
                { candidato.inscricoes.length > 0 &&
                   currencyFormatter(Number(candidato.inscricoes[0].primeira_opcao_valor_mensalidade))
                }
              </p>
              <p>% solicitado de bolsa: {candidato.bolsa_restituiveis_attributes[0].percentual_pretendido} %</p>

              <p style={{fontWeight: 'bold'}}>
                Você pode retornar e indicar um outro fiador ou finalizar e verificar outras possibilidade de financiamento estudantil. 
              </p>
             
              <TextoCandidatoNaoAprovado />

              <Button onClick={handleClose} >
                Finalizar
              </Button>

            </>
          )
        }

      </Content>
    </>
  )
}

export default CandidatoNaoAprovado;
