import React from 'react'
import RodapeContatos from './Contatos';
import {Content, Phone} from './styles'

const TextoCandidatoNaoAprovado = () => {
  return (
    <>
      <Content>
        <p>
          Se houver interesse em complementar o custeio dos estudos com algum tipo de financiamento, a
          ESPM indica o Crédito Universitário Bradesco, uma linha especial de crédito estudantil com taxa
          mensal de juros de 0,75%, bem abaixo de valores praticados pelo mercado financeiro, destinada ao
          financiamento de cursos de graduação.
        </p>

        <p>
          Mais informações poderão ser obtidas diretamente no site, em qualquer posto de atendimento bancário
          do Bradesco ou por e-mail, cujos contatos seguem abaixo:
        </p>

        <p >
          <div style={{display: 'flex'}}>
            <p>
            Site: <a
              href="https://banco.bradesco/html/classic/produtos-servicos/emprestimo-e-financiamento/universitario/credito-universitario-bradesco.shtm"
              target="_blank"
              rel="noreferrer"
              alt="Site do Bradesco"
            >
              Banco Bradesco
             </a>
            </p>

            <p style={{marginLeft: '16px'}}>
                Tel.: <a href="tel:+551150856652">(11) 5085-6652</a>
            </p>

            <p style={{marginLeft: '16px'}}>
              E-mail: <a href="mailto:0134.gerencia@bradesco.com.br">0134.gerencia@bradesco.com.br</a>
            </p>
          </div>

        </p>

        <p>
          Outra opção seria o FIES, um programa do governo que possibilita uma melhor organização
          financeira a longo prazo e sem juros.  A ESPM mantém convênio com o FIES para todos os cursos
          de graduação, nas unidades de São Paulo, Rio de Janeiro e Porto Alegre. A concessão e aprovação
          do FIES dependerá da legislação em vigor no período de matrícula.
        </p>

        <p>
          O período de inscrições para 2021/2 ainda não foi divulgado, mas as informações completas poderão
          ser obtidas diretamente no site &nbsp;
          
          <a
            href="http://fiesselecao.mec.gov.br"
            target="_blank"
            rel="noreferrer"
            alt="Site do Bradesco"
          > 
            http://fiesselecao.mec.gov.br.
          </a>
        </p>

        <p>
          Em caso de dúvidas, estaremos à disposição para os esclarecimentos necessários.
        </p>

        <RodapeContatos />
      
      </Content>
      
    </>
  )
}

export default TextoCandidatoNaoAprovado;
