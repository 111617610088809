import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

import ModalSteps from '../ModalSteps';
import TableStep from '../TableStep';
import useCandidato from '../../context/CandidatoContext';
import { Container, Form, Input, Select, Label, Strong, Button, 
  ButtonModal, CurrencyInput, Row, TotalLabel, DateInput } from './styles';
import { v4 as uuidv4 } from 'uuid';

import api from '../../api';
import { currencyFormatter, currencyConfig} from '../../util/constants';

const GrupoFamiliar = ( {disabledButton} ) => {

  const { candidato,
    updateStatusCandidato,
    updateCandidatoEAtributosAninhados } = useCandidato();
  const [showModal, setShowModal] = useState(false);
  const [rendaBruta, setRendaBruta] = useState(0);
  const [valorTotal, setValorTotal] = useState(0.00)
  const [valorTotalPercapita, setValorTotalPercapita] = useState(0.00)
  const [listGroupFamiliar, setListGroupFamiliar] = useState([])
  const [grupoFamiliar, setGrupoFamiliar] = useState({
    id: null,
    uuid: uuidv4(),
    nome: "",
    parentesco: "avô",
    contribue_renda: false,
    responsavel_financeiro: false,
    atividade_principal: "",
    situacao_profissional: "",
    data_nascimento: "",
    estado_civil: 'casado',
    valor: ""
  })

  useEffect(() => {
    setValorTotal(somaValorTotal);
    setValorTotalPercapita(somaValorTotalPercapita);
    getGrupoFamiliar();
    disabledButton(false)
  }, [candidato])

  function existResponsavelFinanceiro() {
    const newListGroupFamiliar = candidato.receitas_attributes.filter((registro) => registro._destroy !== true)
    
    return newListGroupFamiliar.some((registro) => registro.responsavel_financeiro === true)
  }

  function getGrupoFamiliar() {
    const newListGroupFamiliar = candidato.receitas_attributes.filter((registro) => registro._destroy !== true)
    setListGroupFamiliar(newListGroupFamiliar)
    return newListGroupFamiliar
  }

  function uploadFile(file, item) {
    let newAttribute = ''
    candidato.receitas_attributes.map(attr => {
      let currentListReceitas = candidato.receitas_attributes.filter((registro) => registro.uuid !== item.uuid)
      if (attr.uuid === item.uuid) {
        newAttribute = { ...attr, image: file }
      }
      currentListReceitas.push(newAttribute)
      
      updateCandidatoEAtributosAninhados({
        'receitas_attributes': currentListReceitas
      })
      return attr;
    })
    const data = new FormData();
    data.append(`candidato[receitas_attributes][${item.id}][image]`, file);
    data.append(`candidato[receitas_attributes][${item.id}][id]`, item.id);
    api.patch(`/candidatos/${candidato.id}`, data).then(response => {
      //code
    });
  }

  function updateCandidatoReceitas(receita) {
    let bolsa_social = []
    let bolsa_restituivel = []
    if (candidato.bolsa === 'bolsa_social') {
      if (candidato.bolsa_sociais_attributes.length > 0) {
        if (candidato.bolsa_sociais_attributes[0].id) {
          bolsa_social = [{...candidato.bolsa_sociais_attributes[0], _destroy: true}]
          bolsa_restituivel = [{...candidato.bolsa_restituiveis_attributes[0], _destroy: true}]
        } else {
          bolsa_social = []
        }
      }
    } else if (candidato.bolsa === 'bolsa_restituivel') {
      bolsa_social = [{...candidato.bolsa_sociais_attributes[0], _destroy: true}]
      bolsa_restituivel = [{...candidato.bolsa_restituiveis_attributes[0], _destroy: true}]
    } else {
      bolsa_restituivel = []
    }

    updateCandidatoEAtributosAninhados({
      'bolsa': null,
      'status': 'iniciado',
      'bolsa_sociais_attributes': bolsa_social,
      'bolsa_restituiveis_attributes': bolsa_restituivel,
      'receitas_attributes': receita
    })
  }


  function addGrupoFamiliar() {
    updateStatusCandidato('iniciado')
    if (candidato.receitas_attributes.some((item) => item.uuid === grupoFamiliar.uuid)) {
      const atualizaReceita = candidato.receitas_attributes.map((item) => item.uuid === grupoFamiliar.uuid ? grupoFamiliar : item)
      // updateCandidato('receitas_attributes', atualizaReceita)
      updateCandidatoReceitas(atualizaReceita)
    } else {
      setGrupoFamiliar({ ...grupoFamiliar, uuid: uuidv4() })
      // updateCandidato('receitas_attributes', [...candidato.receitas_attributes, grupoFamiliar])
      updateCandidatoReceitas([...candidato.receitas_attributes, grupoFamiliar])
    }
    limparCampos();
    setShowModal(false);
  }

  function apagarGrupoFamiliar(item) {
    candidato.receitas_attributes.map(receita => {
      let newAttribute = ''
      let currentListReceitas = candidato.receitas_attributes.filter((registro) => registro.uuid !== item.uuid)
      //let currentListReceitas = candidato.receitas_attributes
      if (receita.uuid === item.uuid) {
        newAttribute = { ...receita, _destroy: true }
      }
      if (newAttribute !== '') {
        currentListReceitas.push(newAttribute)
        updateCandidatoEAtributosAninhados({
          'receitas_attributes': currentListReceitas//currentListReceitas
        })
      }

      return receita;
    })

  }

  function validGroupFamiliar() {
    if (validaCampos()) {
      addGrupoFamiliar()
      setValorTotal(somaValorTotal);
      setValorTotalPercapita(somaValorTotalPercapita);

    } else {
      alert('Por favor preencher todos os campos!')
      setShowModal(true);
    }
  }

  function validaCampos() {
    let result = true
    if (grupoFamiliar.nome === ''
      || grupoFamiliar.atividade_principal === ''
      || grupoFamiliar.situacao_profissional === ''
      || grupoFamiliar.data_nascimento === '')
       {
      result = false
    }

    return result

  }

  function limparCampos() {
    setGrupoFamiliar({
      id: null,
      uuid: uuidv4(),
      nome: "",
      parentesco: "avô",
      contribue_renda: false,
      responsavelFinanceiro: false,
      atividade_principal: "",
      situacao_profissional: "",
      data_nascimento: "",
      valor: "0.00"
    });
    setRendaBruta(0.00);
    setShowModal(false);
  }

  function editarGrupoFamiliar(item) {
    setRendaBruta(Number(item.valor))
    setGrupoFamiliar({
      id: item.id,
      uuid: (item.uuid === '') ? uuidv4() : item.uuid,
      nome: item.nome,
      parentesco: item.parentesco,
      contribue_renda: item.contribue_renda,
      responsavel_financeiro: item.responsavel_financeiro,
      atividade_principal: item.atividade_principal,
      situacao_profissional: item.situacao_profissional,
      data_nascimento: item.data_nascimento, //? formatDate(item.data_nascimento) : "",
      valor: item.valor
    });
    setShowModal(true);
  }

  function somaValorTotal() {
    let soma = 0.00
    getGrupoFamiliar();
    const list = candidato.receitas_attributes.filter((registro) => registro._destroy !== true) || [];
    
    list.map((item) => {
      soma += parseFloat(item.valor)
    })

    return soma
  }

  
  function somaValorTotalPercapita() {
    let count = candidato.receitas_attributes.filter((registro) => registro._destroy !== true).length
    const newValue = parseFloat((somaValorTotal() / count).toFixed(2))
    return (isNaN(newValue) ? 0.00 : newValue)
  }

  // const handleValueChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
  //   setRendaBruta(value);
  //   // setGrupoFamiliar({ ...grupoFamiliar, valor: value })
  // };

  const handleValueChange = (event, value, maskedValue) => {
    //event.preventDefault();
    // console.log(value); // value without mask (ex: 1234.56)
    // console.log(maskedValue); // masked value (ex: R$1234,56)
    setRendaBruta(value);
    setGrupoFamiliar({ ...grupoFamiliar, valor: value })
  };  

  function close() {
    limparCampos();
    setShowModal(true);
  }

  return (
    <Container>
      <Strong>Informar dados grupo familiar</Strong>

      <Row style={{ marginBottom: '16px' }}>
        “Entende-se por grupo familiar, além do próprio candidato, o conjunto de pessoas que
        contribuem para a renda familiar ou usufruem dela na condição de dependentes,
        que sejam relacionados ao candidato pelos seguintes graus de parentesco: pai, padrasto,
        mãe, madrasta, irmãos, cônjuge, companheiro, filho, enteado, avós etc. “
        </Row>

      <Row style={{ justifyContent: 'space-between' }}>
        <ButtonModal title="Adicionar novo membro do grupo familiar" onClick={close} >
          <Icon path={mdiPlus} size="24px" color="white"></Icon>
        </ButtonModal>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          
        }}>
          <TotalLabel >
            <div style={{ }}>
              <label style={{ marginRight: '4px' }}> 
                Renda Per Capita
              </label>
              <div style={{}}>
                {currencyFormatter(valorTotalPercapita)}
              </div>
            </div>
          </TotalLabel>

          <TotalLabel >
            <div style={{ }}>
              <label style={{ marginRight: '4px' }}> 
              Renda Bruta
              </label>
              <div style={{}}>
                {currencyFormatter(valorTotal)}
              </div>
            </div>
          </TotalLabel>

        </div>
      </Row>

      <ModalSteps show={showModal} onClose={() => setShowModal(false)}>
        <Form>
          <Label htmlFor="nomeCompleto">
            Nome Completo
          </Label>
          <Input
            id="nomeCompleto"
            placeholder="Nome Completo"
            value={grupoFamiliar.nome}
            onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, nome: event.target.value })}
            required
          />

          <Row>
            <div>
              <Label>
                Grau de parentesco
              </Label>

              <Select value={grupoFamiliar.parentesco} onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, parentesco: event.target.value })}>
                <option selected value="avô">Avô</option>
                <option value="avó">Avó</option>
                <option value="companheiro"> Companheiro (a)</option>
                <option value="conjuge">Cônjuge</option>
                <option value="enteado">Enteado (a)</option>
                <option value="filho">Filho (a)</option>
                <option value="irmã">Irmã (o)</option>
                <option value="mãe">Mãe</option>
                <option value="pai">Pai</option>
                <option value="padastro">Padastro</option>
                <option value="madastra">Madastra</option>
                <option value="outros">Outros</option>
              </Select>
            </div>

            <Label style={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
              <span>
                Contribui para renda familiar
              </span>
              <Switch
                onChange={(checked) => setGrupoFamiliar({ ...grupoFamiliar, contribue_renda: checked })}
                checked={grupoFamiliar.contribue_renda} />
            </Label>

            <Label style={{ display: 'flex', flexDirection: 'column', alignItems: "center", marginLeft: '32px' }}>
              <span>
                Responsável financeiro
              </span>
              <Switch
                onChange={(checked) => setGrupoFamiliar({ ...grupoFamiliar, responsavel_financeiro: checked })}
                checked={grupoFamiliar.responsavel_financeiro}
                disabled={existResponsavelFinanceiro()}
              />
            </Label>
          </Row>

          <Row style={{display: 'flex'}}>
            <div style={{flex: 1}}>
              <Label>Situação profissional</Label>
              <Select value={grupoFamiliar.situacao_profissional} onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, situacao_profissional: event.target.value })}>
                <option selected value="">Selecione uma opção</option>
                <option value="nao_trabalha">Não Trabalha</option>
                <option value="aposentado">Aposentado</option>
                <option value="autonomo">Autônomo</option>
                <option value="empregado">Empregado</option>
              </Select>
            </div>

            <div style={{flex: 2.5}}>
              <Label htmlFor="atividade_principal">Atividade principal</Label>
              <Input
                id="atividade_principal"
                placeholder="Atividade principal"
                value={grupoFamiliar.atividade_principal}
                onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, atividade_principal: event.target.value })}
                required
              />
            </div>

          </Row>

          <Row>
            <div>
              <Label htmlFor="valorBrutoMensal">Rendimento Bruto Mensal</Label>
              <CurrencyInput  
                className="currency-input"
                currency="BRL" config={currencyConfig()}
                type="text"
                placeholder="0.00"  
                decimalSeparator="," 
                groupSeparator="."
                value={rendaBruta}
                onChange={handleValueChange}
                //onValueChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, valor: event.currentTarget.value  })}
              />
            </div>  
            <div style={{marginLeft: '16px'}}>
              <Label htmlFor="dataNascimento">Data Nascimento</Label>
              <DateInput
                id={`DadosFamiliarEmpresaDataAdimissao`}
                type="date"
                placeholder="00/00/0000"
                value={grupoFamiliar.data_nascimento}
                onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, data_nascimento: event.target.value })}
              />
            </div>  


          </Row>        

        </Form>

        <div style={{ display: 'flex', width: '100%', marginTop: '8px' }}>
          <Button
            onClick={limparCampos}
          >
            Cancelar
          </Button>

          <Button
            // onClick={addGrupoFamiliar}
            onClick={validGroupFamiliar}
          >
            Confirmar
          </Button>
        </div>

      </ModalSteps>

      {}

      <TableStep
        //        listGroupFamiliar={candidato.receitas_attributes} 
        listGroupFamiliar={listGroupFamiliar}
        editarGrupoFamiliar={editarGrupoFamiliar}
        apagarGrupoFamiliar={apagarGrupoFamiliar}
        uploadFile={uploadFile}
      />


    </Container>
  )
}

export default GrupoFamiliar;
