import React from 'react'
import Whatsapp from '../../Whatsapp'
import {Content} from './styles'

const RodapeContatos = () => {
  return (
    <>
    <Content>
      <p>
        <ul>
          <li>
            ESPM São Paulo <br />
            Telefone: <a href="tel:+551150854658">(11) 5085-4658</a> <br />
            Bolsa Social:
              <a href="tel:+551150854343">(11) 5085-4343</a>/
              <a href="mailto:bolsasocial@espm.br"> bolsasocial@espm.br </a>
            <br />

            Bolsa Restituível:
              <a href="tel:+551150854343">(11) 5085-4343</a> /
              <a href="mailto:">bolsarestituível@espm.br</a> <br />
            FIES: <a href="tel:+551150856720">(11) 5085-6720</a> /
              <a href="mailto:fies@espm.br">fies@espm.br</a>
          </li>

          <li style={{ marginBottom: '16px' }}>
            ESPM Rio de Janeiro  <br />
            Telefone: <a href="tel:+552122162000">(21) 2216-2000</a> (opção 5) <br />
            Bolsa Social: <a href="mailto:bolsasocialrj@espm.br">bolsasocialrj@espm.br</a> <br />
            Bolsa Restituível: <a href="mailto:bolsarestituivelrj@espm.br">bolsarestituivelrj@espm.br</a> <br />
            FIES: <a href="mailto:financeirorj@espm.br">financeirorj@espm.br</a>
          </li>

          <li style={{ marginBottom: '16px' }}>
            ESPM Porto Alegre <br />
            Telefone: <a href="tel:+555132181300">(51) 3218-1300</a> (opção 6) <br />
            Bolsa Social: <a href="mailto:bolsasocialsul@espm.br">bolsasocialsul@espm.br</a> <br />
            Bolsa Restituível: <a href="mailto:bolsarestituivelpoa@espm.br">bolsarestituivelpoa@espm.br</a> <br />
            FIES: <a href="mailto:financeiro-rs@espm.br">financeiro-rs@espm.br</a>
          </li>
        </ul>
      </p>
      <Whatsapp />
    </Content>
    </>
  )
}

export default RodapeContatos


