import React from 'react';

import LogoBg from '../../assets/logo.png';
import { Container, Header, HeaderTitle, ProgressBar, Row, Logo, Text, Button } from './styles';
import RodapeContatos from '../../components/TextoCandidatoNaoAprovado/Contatos';

const FormatoDocumentos = () => {

  function closeWin() {
    return window.close(); 
  }

  return (
    <>
      <Container>

      <Header>
        <Row>
          <HeaderTitle>
            Inscrição para concessão de bolsas
          </HeaderTitle>
          <Logo bg={LogoBg} /> 
        </Row>

        <ProgressBar />

      </Header>


      <Text style={{ margin: "16px 0" }}>
        <div>
          <p>Os documentos relacionados abaixo deverão ser apresentados, obrigatoriamente, pelas seguintes pessoas:</p>
          
          <p> 
            - Aluno e grupo familiar - entende-se por grupo familiar, além do próprio aluno, 
            o conjunto de pessoas que contribuem para a renda familiar ou usufruem dela na condição de dependentes, 
            que sejam relacionados ao aluno pelos seguintes graus de parentesco: pai, padrasto, mãe, madrasta,
            irmãos, cônjuge, companheiro, filho, enteado, avós etc., desde que todas moradoras em um mesmo domicílio.
          </p>
              
          <p> - Responsável financeiro</p>

          <p> - Fiador</p>

          <p className="title"> RG e CPF </p>
          
          <p>Devem ser entregues cópias legíveis, frente e verso, de uma das opções abaixo:</p>
          
          <ul>
            <li>Carteira de identidade ou documento de valor equivalente</li>
            <li>Identidade Civil</li>
            <li>Carteira de Habilitação</li>
            <li>Cédula de Identidade de Estrangeiro (RNE)</li>
            <li>CTPS – Carteira de Trabalho e Previdência Social (desde que tenha o número do RG)</li>
            <li>Carteira de exercício de profissão regulamentada por lei (Ex: OAB, CREA etc.)</li>
            <li>Passaporte</li>
            <li>CPF - Impressão do comprovante atual de inscrição do CPF, com autenticidade da Receita Federal.</li>
          </ul>

          <p>
            Importante: No contrato, os números dos RGs devem ser iguais aos dos documentos entregues. 
            As assinaturas no contrato devem ser iguais aos dos documentos entregues. 
            Todos os documentos devem estar dentro do prazo de validade.
          </p>

          <p className="title"> Comprovante de Renda </p>

          <p>
            Renda com vínculo empregatício: holerite dos últimos três meses, em nome de cada membro 
            que compõe a renda familiar.
          </p>

          <ul>
            <li>Empresário, profissional liberal, trabalhador autônomo ou informal: DECORE, considerando os últimos 4 meses, feitos por um contador inscrito no CRC, com carimbo e assinatura.</li>
            <li>Benefícios recebidos do INSS: comprovantes de recebimento dos últimos três meses. Os extratos de pagamentos poderão ser emitidos pela internet, no endereço eletrônico &nbsp; 
              <a
                href="https://meu.inss.gov.br"
                rel="noreferrer"
                target="_blank">
                https://meu.inss.gov.br.
              </a>
            </li>
            <li>Outros rendimentos regulares do grupo familiar (aluguel etc.) também devem ser declarados.</li>
          </ul>

          <p>
            Obs.: Apenas o salário base e adicionais fixos (insalubridade, anuênio, periculosidade etc.) serão considerados como renda.
          </p>

          <p>
          Em caso de desemprego do candidato, pais ou cônjuge, apresentar Carteira de Trabalho (com a baixa), bem como cópia da página com dados do trabalhador e da data de saída ou seguro-desemprego.
          </p>
          
          <p className="title"> Última declaração de Imposto de Renda</p>
       
          <p> Declaração completa, com todas as páginas e o recibo de entrega, do ano/calendário 2020. Declaração de imposto de renda em nome de pessoa jurídica (PJ) não é aceita.</p>

          <p> Em caso de falecimento do cônjuge ou separação judicial, deverão ser encaminhadas a certidão de óbito ou a certidão de separação judicial. Se a separação não estiver legalizada, apresentar uma declaração de um dos cônjuges, desde que registrada em cartório.</p>

          <p> 
            Ao cônjuge do fiador serão solicitados o RG, CPF e certidão de casamento. &nbsp;
          </p>
          <hr />
          <p> 
            Em caso de duvidas, estaremos à disposição para os esclarecimentos necessários.
          </p>
          <RodapeContatos />
          
        </div>

      </Text>

      <Button onClick={closeWin}>
        Fechar
      </Button>

      </Container>
      
    </>
  )
}

export default FormatoDocumentos;
