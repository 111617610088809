import React from 'react';
import { Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import Home from '../src/pages/Home';
import FormatoDocumentos  from '../src/pages/FormatoDocumentos';
import ConfirmSteps from '../src/pages/ConfirmSteps';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/formatdocumentos" component={FormatoDocumentos} />
        <Route exact path="/confirmsteps/:step" component={ConfirmSteps} />
      </Switch>
    </Router>
  )
}

export default Routes;