import React, {createContext, useContext, useState} from 'react';
import CadidatoAprovadoService from '../api/candidato_aprovado';
import usePersistedState from '../util/usePersistedState';
import api from '../api';
    //JSON.parse = quando pega do localstorage
    //Stringfy   = quado for setar no localstorage

const CandidatoContext = createContext();

const CandidatoProvider = ({children}) => {
  const [candidato, setCandidato] = usePersistedState('candidato', null);
  const [candidatoAprovado, setCandidatoAprovado] = useState({bolsas: []});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const localeCandidato = (cpf) => {
    setLoading(true)
    setError('');
    api.get(`/candidatos?cpf=${cpf}`).then(response => {
      if (response.data.message) {
        setError(response.data.message.message);
      } else {
        setCandidato(response.data.candidato[0])
      }
      setLoading(false)
    }).catch(err => {
      setError('Aconteceu um erro de conexão.')
      setLoading(false)
    })
  }

  const updateLoading = (value) => {
    setLoading(value)
  }

  const comparePhone = (phone) => {
    const newPhoneCandidato = candidato.numero_celular.replace(/[^a-z0-9]/gi, '')

    setLoading(true)
    setError('');

    if (phone !== newPhoneCandidato) {
      setError(`Informe o numero do telefone válido` );
    } else {
      setLoading(false)
    }

  }

  const putCandidato = () => {
    setLoading(true)
    api.patch(`/candidatos/${candidato.id}`, {candidato: candidato}).then(response => {
      localeCandidato(candidato.cpf)
      setLoading(false)
    }).catch(err => {
      setError('Aconteceu um erro de conexão.')
      setLoading(false)
    })
   
  }

  const putCandidatoFinalStatus = (status) => {
    setLoading(true)
    //const newCandidato = {...candidato, status: status}
    api.patch(`/candidatos/${candidato.id}`, {candidato: {status: status}}).then(response => {
      //localeCandidato(candidato.cpf)
      setLoading(false)
    }).catch(err => {
      setError('Aconteceu um erro de conexão.')
      setLoading(false)
    })
  }  

  const updateCandidato = (key, data) => {
    const newCandidato = {...candidato, [key]: data}
    //setCandidato({...candidato, [key]: data})
    setCandidato(newCandidato)
  }

  const updateStatusCandidato = (status) => {
    setCandidato({...candidato, status: status})
  }

  const updateEscolheBolsa = (bolsa) => {
    const newCandidato = {...candidato, bolsa: bolsa}
    setCandidato(newCandidato)
  }

  const updateCandidatoEAtributosAninhados = (newValues = {}) => {
    const newCandidato = newValues
    setCandidato({...candidato, ...newCandidato});
    //setCandidato(newCandidato)
  }

  const localeCandidatoAprovado = async () => {
    const response = await CadidatoAprovadoService.show(candidato.id);
    const newCandidatoAprovado = response.data 
    setCandidatoAprovado(newCandidatoAprovado)
  }

  return (
    <CandidatoContext.Provider value={{
      candidato,
      loading, 
      error, 
      localeCandidato, 
      comparePhone,
      updateLoading, 
      putCandidato, 
      updateCandidato, 
      updateStatusCandidato, 
      candidatoAprovado,
      updateEscolheBolsa,
      updateCandidatoEAtributosAninhados,
      putCandidatoFinalStatus,
      localeCandidatoAprovado}}>
      {children}
    </CandidatoContext.Provider>
  )

}

const useCandidato = () => {
  const context = useContext(CandidatoContext) 

  if (!context) {
    throw new Error('Use Candidato, deve ser usado com um CandidatoProvider')
  }

  return context
}

export {CandidatoProvider, useCandidato as default}
