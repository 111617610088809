import styled from "styled-components";
import { TextColor , Bordor} from "../../util/constants";

// export const Title = styled.span`
//   display: block;
//   color: ${TextColor};
//   font-weight: 700;
//   font-size: 24px;

//   margin-bottom: 24px;
// `;

export const SubTitle = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: bold;
  font-size: 20px;

  margin-bottom: 24px;
`;

// export const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   > div {
//     span {
//       flex: 0 0 420px;
//       margin-right: 16px;
//     }
//   }
// `;

// export const Row = styled.div`
//   display: flex;
//   align-items: center;
// `;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  
  > div {
    span {
      flex: 0 0 120px;
      margin-right: 16px;
    }
  }
`;

export const Select = styled.select`
  background: #fff; 
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  /* padding-right: 10px; */
  margin-bottom: 16px; 
  width: 100%;

  
`;

export const Label = styled.label`
  display: block;
  color: ${TextColor};
  margin-bottom: 8px;
  font-weight: 600;
  justify-content: center;
  > span + div {
    margin-top: 8px;
  }
`;

export const Input = styled.input`
  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;

  width: 100%;
`;

export const Strong = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  
`;

export const Title = styled.span`
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;

  display: flex;
  
  align-items: center; 
  justify-content: space-between; 
  p {
    width: 320px;
    font-size: 14px;
    font-weight: normal;
    margin-top: 6px;
  }

`;

export const ButtonModal = styled.button`
  align-self: flex-start;

  width: 500px;
  height: 40px;
  margin-bottom: 8px;

  border-radius: 5px;
  border: none;

  font-weight: 600;
  font-size: 16px;
  text-align: center;

  background: ${Bordor};
  color: white;

  cursor: pointer;

`;
