import styled from "styled-components";
import { Red } from "../../util/constants";

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  z-index: 15;

  pointer-events: none;
  opacity: 0;
  transition: all 0.15s ease;
`;

export const Modal = styled.div`

  display: inline-block;
  position: relative;

  background: ${Red};
  border-radius: 5px;
  
  padding: 16px;
  padding-top: 56px;

  max-width: 1024px;
  
  z-index: 20;
  pointer-events: none;
  opacity: 0;
  transition: all 0.15s ease;
`;

export const ButtonCloseModal = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;

  border: none;
  background: transparent;
  cursor: pointer;

`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center center;
 
  z-index: 15;
  pointer-events: none;
  &.show {
    pointer-events: auto;
    ${Overlay} {
      opacity: 1;
      pointer-events: auto;
    }
    ${Modal} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;