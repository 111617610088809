import styled from "styled-components";
import InputMask from 'react-input-mask';
import { TextColor, Bordor } from "../../util/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  
  > div {
    span {
      flex: 0 0 120px;
      margin-right: 16px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 768px;
  
  color: black;
`;

export const Select = styled.select`
  background: #fff; 
  border-radius: 5px;
  border: none;
  height: 45px;
  padding: 0 13px;
  /* padding-right: 10px; */
  margin-bottom: 16px; 
  width: 100%;

  
`;

export const Label = styled.label`
  display: block;
  color: ${TextColor};
  margin-bottom: 8px;
  font-weight: 600;
  > span + div {
    margin-top: 8px;
  }
`;

export const Input = styled(InputMask)`
  background: white;
  border-radius: 5px;
  border: none;
  height: 45px;
  padding: 0 8px;
  margin-bottom: 14px;

  width: 100%;
`;

export const Strong = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

export const Button = styled.button`
  flex: 1;
  height: 50px;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background: #4D5864;
  cursor: pointer;
 
  &:last-of-type {
    background: ${Bordor};
    margin-left: 32px;
  }

  opacity: 0.8;
  transition: all 0.15s ease;

  &:hover {
    opacity: 1;
  }
`;

export const Table = styled.table`

  width: 100%;
  background: #ccc;

  border-radius: 5px;
  
  th {
    padding: 16px 8px;
    background: ${Bordor} ;
    color: white;
    &:first-of-type {
      border-top-left-radius: 5px; 
    }
    &:last-of-type {
      border-top-right-radius: 5px; 
    }
  }

  tbody {
    tr {
      cursor: pointer;
      transition: all 0.15s ease;

      &:hover {
        background: #d9d9d9;
      }
      &:nth-of-type(even) {
        background: #e9e9e9;
        &:hover {
        background: #d9d9d9;
      }
      }
    }

    tr > td {
      padding: 16px 8px;
      &.alignLeft {
        text-align: left;
      }
      &.alignRight {
        text-align: right;
      } 
      &.alignCenter {
        text-align: center;
      }
    }
  }
`;