import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import useCandidato from '../../context/CandidatoContext';

import TextoCandidatoNaoAprovado from '../../components/TextoCandidatoNaoAprovado';
import { Content, Title, Paragrafo, Box, Button } from './styles';

// import winner from '../../assets/winners.svg';
//import pendingApproval from '../../assets/pending_approval.svg'

const CandidatoAprovado = ( { disabledButton } ) => {
  const history = useHistory();
  const { 
    candidato,
    candidatoAprovado, 
    localeCandidatoAprovado, 
    putCandidatoFinalStatus } = useCandidato();

  function fetchCandidatoAprovado() {
    localeCandidatoAprovado()
  }

  useEffect(() => {
    fetchCandidatoAprovado();
  }, []);
  
  useEffect(() => {
    disabledButton(!candidatoAprovado.aprovado)
  }, [candidatoAprovado]);

  function handleClose() {
    putCandidatoFinalStatus('Não elegível');   
    history.push(`/`);
    localStorage.removeItem('candidato');
    window.location.reload();
   }

  return (
    <>
      <Content >
        { candidato && candidatoAprovado && candidatoAprovado.aprovado ? //candidatoAprovado.aprovado ?
          (
            <>
            <Title>Parabéns {candidato.nome}, você está elegível para concorrer à(as) opção(ões) de bolsa de estudo,
             discriminada(s) a seguir.</Title>
            {/* <img src={winner} alt="Winner" style={{ width: '50%', margin: '32px auto' }}/>  */}
            </>
          )
         :
         (
          <>
            <Box>
              <div>
                <Paragrafo> 
                  A renda per capita mensal informada sobre o seu grupo familiar não permite que você participe de algum 
                  dos processos de bolsas oferecidas pela ESPM.
                </Paragrafo> 

                <Paragrafo> 
                  Caso você perceba que algum dado sobre os componentes do seu grupo familiar ou os rendimentos mensais 
                  foram preenchidos de forma incorreta, será possível retornar ao início do formulário e efetuar as correções 
                  necessárias.              
                </Paragrafo>
                <Paragrafo>
                  Lembramos que todos os documentos comprobatórios referentes à composição do grupo familiar e rendimentos mensais, 
                  entre outros, deverão ser apresentados exatamente da forma descrita no regulamento de cada benefício e que estão 
                  disponíveis em nosso site &nbsp;
                  <a
                    href="https://www.espm.br/bolsa"
                    rel="noreferrer"
                    target="_blank">
                    https://www.espm.br/bolsa
                  </a>.
                </Paragrafo>
              </div>

              {/* <img src={pendingApproval} alt="Pendente de aprovação" style={{ width: '15%' }}/> */}
            </Box>

            <TextoCandidatoNaoAprovado />

            <Button onClick={handleClose} >
              Finalizar
            </Button>
          </>
         )
        }
        
      </Content >
    </>
  )
}

export default CandidatoAprovado;