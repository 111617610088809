import styled from "styled-components";
import { TextColor, TextButton, Bordor } from "../../util/constants";

export const Container = styled.div`
  overflow-y: scroll;
  position: relative;
  margin: 0 auto;
  padding-top: 40px;
  height: 720px;
  /* transform: translate(-50%, -50%); */

  max-width: 1024px;
  /* display: flex; */
  /* flex-direction: column; */
  font-size: 14px;

`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.p`
  font-size: 24px;
  color: ${TextColor};
  font-weight: 700;
  margin-left: 8px;
`;

export const Logo = styled.div`
  width: 97px;
  height: 37px;
  background: ${props => `url(${props.bg})` };
  background-size: cover;
  background-position: center;
  margin-left: auto;
`;

export const ProgressBar = styled.div`
  position: relative;
  /* flex: 0 0 100%; */
  height: 4px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);

  &::before {
    content: '';
    position: absolute;
    background: ${TextColor};
    border-radius: 2px;
    height: 100%;
    top: 0;
    left: 0;
    width: 25%;
  }
`;

export const Text = styled.div`
  
  p {
    border: 1 solid red;
    margin-bottom: 14px;
    line-height: 1.3;
  }

  .title {
    font-weight: 700;
  }

  ul {
    list-style: square;
    margin-left: 24px;
    li {
      line-height: 1;
      margin-bottom: 8px;
    }
  }

`;

export const Button = styled.button`
  flex: 1;
  //display: block;
  height: 50px;
  border-radius: 5px;
  border: none;
  color: ${TextButton};
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background: #4D5864;
  cursor: pointer;
  background: ${Bordor};
  width: 100%;
`;