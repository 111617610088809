import React, { useEffect, useState } from 'react'
import Icon from '@mdi/react';
import { mdiPencil } from '@mdi/js';

import useCandidato from '../../context/CandidatoContext';
import ModalSteps from '../ModalSteps';

import { Content, Title, Form, Input, Button, DateInput, Row, Label, Table } from './styles';

import { Gray, Bordor, maior18Anos, currencyFormatter } from "../../util/constants.js";

const DadosEmpresa = ( { disabledButton } ) => {
  const { candidato, updateCandidato } = useCandidato();
  const [showModal, setShowModal] = useState(false);
  const [listGroupFamiliar, setListGroupFamiliar] = useState([])
  const [grupoFamiliar, setGrupoFamiliar] = useState({})
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setListGroupFamiliar(candidato.receitas_attributes.filter( (receita) => maior18Anos(receita.data_nascimento) ))
  }, [candidato])

  useEffect(() => {
    disabledButton(!validAllGrupoFamiliar())
  }, [isValid, listGroupFamiliar])

  function validAllGrupoFamiliar() {
    let valid = false;
    let i = 0;
    while (i < listGroupFamiliar.length) {
      valid = validaCampos(listGroupFamiliar[i])
      if (valid) {
        setIsValid(true)
      } else {
        setIsValid(false)
        break
      }
      i += 1;
   }
    return valid
  } 

  function validaCampos(item) {
    let result = true
    // if ((grupoFamiliar.situacao_profissional === 'empregado') && (grupoFamiliar.empresa === '' || grupoFamiliar.profissao === '' )) {
    //   result = false
    // }
    if (!item.situacao_profissional) {
      return false
    }

    if ((item.situacao_profissional === 'empregado') && (
      item.situacao_profissional === '' || item.situacao_profissional === null
      || item.empresa === ''            || item.empresa === null
      || item.profissao === ''          || item.profissao === null
      || item.cargo === ''              || item.cargo === null
      || item.empresa_cep === ''        || item.empresa_cep === null
      // || item.empresa_tipo_logradouro === ''
      || item.empresa_endereco === ''   || item.empresa_endereco === null
      || item.empresa_numero === ''     || item.empresa_numero === null
      || item.empresa_bairro === ''     || item.empresa_bairro === null
      || item.empresa_cidade === ''     || item.empresa_cidade === null
      || item.empresa_uf === ''         || item.empresa_uf === null
      || item.empresa_pais === ''       || item.empresa_pais === null
      || item.empresa_telefone === ''   || item.empresa_telefone === null
      || item.empresa_data_adimissao === '' || item.empresa_data_adimissao === null
      )) {
      result = false
    }
    return result
  }

  function validGroupFamiliar() {
    if (validaCampos(grupoFamiliar)) {
      saveGrupoFamiliar()
    } else {
      alert('Por favor preencher todos os campos!')
      setShowModal(true);
    }
  }

  function saveGrupoFamiliar() {
    if (candidato.receitas_attributes.some((item) => item.uuid === grupoFamiliar.uuid)) {
      const atualizaReceita = candidato.receitas_attributes.map((item) => item.uuid === grupoFamiliar.uuid ? grupoFamiliar : item)
      updateCandidato('receitas_attributes', atualizaReceita)
    }

    limparCampos();
    setListGroupFamiliar(candidato.receitas_attributes.filter((receita) => receita.contribue_renda === true))
    setShowModal(false);
  }

  function editarGrupoFamiliar(item) {
    setGrupoFamiliar({
      id: item.id,
      uuid: item.uuid,
      nome: item.nome,
      parentesco: item.parentesco,
      contribue_renda: item.contribue_renda,
      responsavel_financeiro: item.responsavel_financeiro,
      atividade_principal: item.atividade_principal,
      valor: item.valor,
      cpf: item.cpf,
      rg: item.rg,
      data_nascimento: item.data_nascimento,
      nacionalidade: item.nacionalidade,
      email: item.email,
      estado_civil: item.estado_civil,
      telefone_contato: item.telefone_contato,
      cep: item.cep,
      tipo_logradouro: item.tipo_logradouro,
      endereco: item.endereco,
      numero: item.numero,
      bairro: item.bairro,
      cidade: item.cidade,
      complemento: item.complemento,
      uf: item.uf,
      pais: item.pais,
      situacao_profissional: item.situacao_profissional, // ? item.situacao_profissional : "Empregado",
      empresa: item.empresa, // ? item.empresa : "Banco do Nordeste",
      profissao: item.profissao, // ? item.profissao : "Analista de Sistemas",
      cargo: item.cargo, // ? item.cargo : "Diretor",
      empresa_cep: item.empresa_cep, // ? item.empresa_cep : "60743-902",
      empresa_tipo_logradouro: item.empresa_tipo_logradouro, // ? item.empresa_tipo_logradouro : "",
      empresa_endereco: item.empresa_endereco, // ? item.empresa_endereco : "Av. Dr. Silas Munguba",
      empresa_numero: item.empresa_numero, // ? item.empresa_numero : "5700",
      empresa_bairro: item.empresa_bairro, // ? item.empresa_bairro : "Passaré",
      empresa_cidade: item.empresa_cidade, // ? item.empresa_cidade : "Fortaleza",
      empresa_complemento: item.empresa_complemento, // ? item.empresa_complemento : "posto passare",
      empresa_uf: item.empresa_uf, // ? item.empresa_uf : "CE",
      empresa_pais: item.empresa_pais, // ? item.empresa_pais : "Brasil",
      empresa_telefone: item.empresa_telefone, // ? item.empresa_telefone : "85 3299-5400",
      empresa_data_adimissao: item.empresa_data_adimissao, // ? item.empresa_data_adimissao : "01/03/1970",

    });
    setShowModal(true);
  }

  function limparCampos() {
    setGrupoFamiliar({
      nome: "",
      cpf: "",
      rg: "",
      nacionalidade: "",
      estado_civil: "",
      telefone_contato: "",
      cep: "",
      tipo_logradouro: "",
      numero: "",
      bairro: "",
      cidade: "",
      complemento: "",
      uf: "",
      pais: "",
      //
      situacao_profissional: "",
      empresa: "",
      profissao: "",
      cargo: "",
      empresa_cep: "",
      empresa_tipo_logradouro: "",
      empresa_endereco: "",
      empresa_numero: "",
      empresa_bairro: "",
      empresa_cidade: "",
      empresa_complemento: "",
      empresa_uf: "",
      empresa_pais: "",
      empresa_telefone: "",
      empresa_data_adimissao: "",

    });
    setShowModal(false);
  }

  function situacaoProfissional(value) {
    switch (value) {
      case "nao_trabalha":
        return "Não Trabalha";
      case "aposentado":
        return "Aposentado";
      case "autonomo":
        return "Autônomo";
      case "empregado":
        return "Empregado";
      default: 
        return ""
    }
  }
  
  function close() {
    validaCampos(grupoFamiliar)
    setShowModal(false)
  }

  return (
    <>
      <Title>Informar dados profissionais dos familiares.</Title>

      {/* <ModalSteps show={showModal} onClose={() => setShowModal(false)}> */}
      <ModalSteps show={showModal} onClose={() => close()}>
        <Content>
        {grupoFamiliar.situacao_profissional === 'empregado' &&
          <Row>
            <div style={{
              width: '100%',
              marginBottom: '16px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              color: Bordor,
            }}
            >
              <p>
                ATENÇÃO: É OBRIGATÓRIO O PREENCHIMENTO DE TODOS OS CAMPOS PARA PROSSEGUIR
              </p> 
            </div>
          </Row>
        }
          <Form>
            <Row>
              <div style={{ flex: 2 }}>
                <Label>Nome</Label>
                <Input
                  id={`DadosFamiliarNome`}
                  placeholder=""
                  value={grupoFamiliar.nome}
                  onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, nome: event.target.value })}
                  disabled={true}
                />
              </div>

              {/* <div style={{ flex: 1, marginLeft: '16px' }}>
                <Label>Situação Profissional</Label>
                <Select value={grupoFamiliar.situacao_profissional} onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, situacao_profissional: event.target.value })}>
                  <option selected value="aposentado">Aposentado</option>
                  <option value="autonomo">Autônomo</option>
                  <option value="empregado">Empregado</option>
                </Select>
              </div> */}

              <div style={{ flex: 1, marginLeft: '16px' }}>
                <Label>Renda Mensal</Label>
                <Input
                  id={`DadosFamiliarRendaMensal`}
                  placeholder="0.00"
                  value={grupoFamiliar.valor}
                  disabled={true}
                  onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, valor: event.target.value })}
                />
              </div>

            </Row>

            {
              grupoFamiliar.situacao_profissional === 'autonomo' && (
                <>
                  <Row>
                    <div style={{ flex: 1}}>
                      <Label>Profissão</Label>
                      <Input
                        id={`DadosFamiliarProfissao`}
                        placeholder=""
                        value={grupoFamiliar.profissao}
                        onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, profissao: event.target.value })}
                      />
                    </div>
                  </Row>
                </>
              )
            }

            {
              grupoFamiliar.situacao_profissional === 'empregado' && (
                <>
                <Row>
                  <div style={{ flex: 2 }}>
                    <Label>Empresa</Label>
                    <Input
                      id={`DadosFamiliarEmpresa`}
                      placeholder=""
                      value={grupoFamiliar.empresa}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa: event.target.value })}
                      
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Profissão</Label>
                    <Input
                      id={`DadosFamiliarProfissao`}
                      placeholder=""
                      value={grupoFamiliar.profissao}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, profissao: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Cargo</Label>
                    <Input
                      id={`DadosFamiliarCargo`}
                      placeholder=""
                      value={grupoFamiliar.cargo}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, cargo: event.target.value })}
                    />
                  </div>

                </Row>

                <Row>
                  <div style={{ flex: 1 }}>
                    <Label>CEP</Label>
                    <Input
                      id={`DadosFamiliarEmpresaCep`}
                      placeholder=""
                      value={grupoFamiliar.empresa_cep}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_cep: event.target.value })}
                      mask="99.999-999"
                    />
                  </div>

                  <div style={{ flex: 2, marginLeft: '16px' }}>
                    <Label>Endereço</Label>
                    <Input
                      id={`DadosFamiliarEmpresaEndereco`}
                      placeholder=""
                      value={grupoFamiliar.empresa_endereco}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_endereco: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 0.5, marginLeft: '16px' }}>
                    <Label>Número</Label>
                    <Input
                      id={`DadosFamiliarEmpresaNumero`}
                      placeholder=""
                      value={grupoFamiliar.empresa_numero}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_numero: event.target.value })}
                    />
                  </div>
                </Row>

                <Row>
                  <div style={{ flex: 1 }}>
                    <Label>Complemento</Label>
                    <Input
                      id={`DadosFamiliarEmpresaComplemento`}
                      placeholder=""
                      value={grupoFamiliar.empresa_complemento}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_complemento: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Bairro</Label>
                    <Input
                      id={`DadosFamiliarEmpresaBairro`}
                      placeholder=""
                      value={grupoFamiliar.empresa_bairro}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_bairro: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Cidade</Label>
                    <Input
                      id={`DadosFamiliarEmpresaCidade`}
                      placeholder=""
                      value={grupoFamiliar.empresa_cidade}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_cidade: event.target.value })}
                    />
                  </div>

                </Row>

                <Row>
                  <div style={{ flex: 0.5 }}>
                    <Label>UF</Label>
                    <Input
                      id={`DadosFamiliarEmpresaUF`}
                      placeholder=""
                      value={grupoFamiliar.empresa_uf}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_uf: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>País</Label>
                    <Input
                      id={`DadosFamiliarEmpresaPais`}
                      placeholder=""
                      value={grupoFamiliar.empresa_pais}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_pais: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Data Admissão</Label>
                    <DateInput
                      id={`fiadorDataNascimento`}
                      type="date"
                      placeholder="00/00/0000"
                      value={grupoFamiliar.empresa_data_adimissao}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_data_adimissao: event.target.value })}
                    />
                  </div>
               

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Telefone</Label>
                    <Input
                      id={`DadosFamiliarEmpresaDataAdimissao`}
                      placeholder=""
                      value={grupoFamiliar.empresa_telefone}
                      onChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, empresa_telefone: event.target.value })}
                      mask="(99)9.9999.9999"
                    />
                  </div>

                </Row>
                </>
              )}
          </Form>

          <div style={{ display: 'flex', width: '100%', marginTop: '8px' }}>
            <Button
              onClick={limparCampos}
            >
              Cancelar
            </Button>

            <Button
              onClick={validGroupFamiliar}
            >
              Confirmar
            </Button>
          </div>

        </Content>
      </ModalSteps>

      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Nome</th>
            <th>Parentesco</th>
            <th>Situação Profissional</th>
            <th>Profissão</th>
            <th style={{ textAlign: 'right' }}>Renda Mensal</th>
            <th></th>
          </tr>
        </thead>

        <tbody>

          {
            listGroupFamiliar.length > 0 ? (
              listGroupFamiliar.map((item, index) => (
                <tr key={index}>
                  <td className="alignLeft">{item.nome}</td>
                  <td className="alignCenter">{item.parentesco}</td>
                  <td className="alignCenter">{situacaoProfissional(item.situacao_profissional)}</td>
                  <td className="alignCenter">{item.profissao}</td>
                  <td className="alignRight">{currencyFormatter(Number(item.valor))}</td>
                  <td>
                    <button
                      style={{ width: "35px", height: "35px", border: "none", background: Gray }}
                      onClick={() => editarGrupoFamiliar(item)}
                    >
                      <Icon path={mdiPencil} size="16px" color="white" />
                    </button>
                  </td>
                </tr>
              ))

            ) : (
              <tr>
                <td className="alignLeft"></td>
                <td className="alignCenter"></td>
                <td className="alignCenter"></td>
                <td className="alignLeft"></td>
                <td className="alignCenter"></td>
                <td className="alignRight"></td>
              </tr>
            )

          }

        </tbody>
      </Table>

    </>
  )
}

export default DadosEmpresa;
