import styled from "styled-components";
import { TextColor, Blue, TextButton, Gray } from "../../util/constants";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  
`;

export const Box = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding: 0 16px;

  width: 368px;
  height: 50px;

  background: ${props => props.checked ? Blue : TextButton};
  border-radius: 5px;
  margin-top: 16px;

  > input {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  > div {
    margin-left: 8px;
    color: ${props => props.checked ? TextButton : TextColor };

    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    cursor: pointer;

    display: flex;
    
    > p {
      margin-left: 124px;  
    }
  }

  > ul {
    position: absolute;
    left: 0;
    bottom: -100%;

    display: flex;
    align-items: center;
    
    width: 100%;
    height: 100%;

    opacity: 0;
    pointer-events: none;
    transform: translateY(16px);
    transition: all 0.15s ease;

    &.show {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }

    > li {
      background: white;
      flex: 1;
      cursor: pointer;

      text-align: center;
      height: 100%;
      
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.1s ease;

      &:first-of-type{
        border-bottom-left-radius: 5px;
      }

      &:last-of-type{
        border-bottom-right-radius: 5px;
      }

      &:hover {
        background: ${Gray};
        color: white;
      }

      &.selected {
        background: ${Blue};
        color: white;
      }

    }

  }

  cursor: pointer;
`
export const Autorizacao = styled.div` 
  background: white;
  padding: 10px 10px;
  max-width: 48%;
  
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.5;
`

export const RadioButton = styled.input`
  
`;

export const Col = styled.div`
  flex: 1;
  height: 100vh;
  padding-left: 64px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &:first-of-type{
    flex: 0 0 450px;
  }
  
`;

export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-of-type{
    align-items: flex-end;
    margin-right: 10px;
    }
`;

export const Strong = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 700;

`;

export const Input = styled.input`
-webkit-appearance: none;
appearance: none;

background: white;
border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;
`;


export const Select = styled.select`
-webkit-appearance: none;
appearance: none;

background: white;
border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;
`;

export const Label = styled.label`
  display: block;
  color: white;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const Title = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

