import React from 'react';
import Icon from '@mdi/react';
import { mdiPencil, mdiDelete, mdiCloudUpload, mdiAbacus } from '@mdi/js';
import NumberFormat from 'react-number-format';
import IntlNumberInput from 'react-intl-number-input';

import {Content, Table} from './styles.js';
import {Gray, Bordor, currencyFormatter}  from "../../util/constants.js";

const TableStep = ({ listGroupFamiliar, editarGrupoFamiliar, apagarGrupoFamiliar, uploadFile }) => {    
  return (
    <Content>
      
      <Table>
        <thead>
          <tr>
            <th style={{textAlign: 'left'}}>Nome</th>
            <th>Parentesco</th>
            <th style={{width: '200px'}} >Contribui na renda familiar</th>
            <th style={{width: '100px'}}>Responsável Financeiro </th>
            <th style={{textAlign: 'left'}}>Atividade</th>
            <th style={{textAlign: 'right'}}>Valor</th>
            <th colSpan={3}></th>
          </tr>
        </thead>

        <tbody>

          { 
            listGroupFamiliar.length > 0 ? (
              listGroupFamiliar.map((item, index) => (
              <tr key={index}>
                <td className="alignLeft"
                    style={{width: '350px'}}
                >{item.nome}</td>
                <td className="alignCenter">{item.parentesco}</td>
                <td className="alignCenter">{item.contribue_renda ? "Sim" : "Não"}</td>
                <td className="alignCenter">{item.responsavel_financeiro ? "Sim" : "Não"}</td>
                <td className="alignLeft">{item.atividade_principal} </td>
                <td className="alignRight">{currencyFormatter(Number(item.valor))}
                  {/* <NumberFormat 
                    value={item.valor} 
                    displayType={'text'}
                    // // decimalSeparator="," 
                    // thousandsGroupStyle=""
                    // thousandSeparator={true}
                    // prefix={'R$'}
                    format={currencyFormatter}
                  /> */}
                  {/* <IntlNumberInput //-webkit-appearance
                    style={{webkitAppearance: 'none', appearance: 'none', border: 'none', background: 'transparent'}}
                    locale="pt-BR"
                    prefix="R$ "
                    precision={2}
                    value={Number(item.valor)}
                    /> */}
                </td>
                <td> 
                  <button 
                    title="Editar membro do grupo familiar"
                    style={{ width: "35px", height: "35px", border: "none", background: Gray }}
                    onClick={() => editarGrupoFamiliar(item)}
                  >
                    <Icon path={mdiPencil} size="16px" color="white"/>
                  </button>
                </td>
                <td>
                  <button 
                    title="Apagar membro do grupo familiar"
                    style={{ width: "35px", height: "35px", border: "none", background: Bordor }}
                    onClick={() => apagarGrupoFamiliar(item)}
                  >
                  <Icon path={mdiDelete} size="16px" color="white"/>
                  </button>
                </td>
              </tr> 
            )) 

            ) : (
              <tr>
                <td className="alignLeft"></td>
                <td className="alignCenter"></td>
                <td className="alignCenter"></td>
                <td className="alignLeft"></td>
                <td className="alignCenter"></td>
                <td className="alignRight"></td>
              </tr>               
            )
          
          }

        </tbody>

      </Table>
    </Content>
  )
}

export default TableStep;
