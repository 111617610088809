import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Step01 from '../../components/ConfirmSteps';
import Step02 from '../../components/GrupoFamiliar';
import Step03 from '../../components/OutrasReceitas';
import Step04 from '../../components/CandidatoAprovado';
import Step05 from '../../components/EscolheBolsa';
import Step06 from '../../components/DespesasMoradia';
import Step07 from '../../components/DespesasAlimentacao';
import Step08 from '../../components/DespesasFuncionario';
import Step09 from '../../components/DespesasEducacao';
import Step10 from '../../components/DespesasSeguro';
import Step11 from '../../components/DespesasTransporte';
import Step12 from '../../components/DespesasSaude';
import Step13 from '../../components/DespesasLazer';
import Step14 from '../../components/DespesasFixas';
import Step15 from '../../components/DespesasOutras';
// import Step16 from '../../components/ResponsavelFinanceiro';
import Step16 from '../../components/DadosPessoais';
import Step17 from '../../components/DadosFamiliares';
import Step18 from '../../components/DadosEmpresa';
import Step19 from '../../components/DadosFiador';
import Step20 from '../../components/CandidatoNaoAprovado';
import Step21 from '../../components/Documentos';
import Step22 from '../../components/FinalBolsa';

import useCandidato from '../../context/CandidatoContext';
import Icon from "@mdi/react";
// import { mdiArrowLeft } from "@mdi/js";

import LogoBg from '../../assets/logo.png';
import { Container, Header, HeaderTitle, ProgressBar, Footer, FooterDown, Button, Row, Logo } from './styles';
import { TextColor } from '../../util/constants';

const ConfirmSteps = () => {
  const history = useHistory();
  const params = useParams();
  const { candidato, 
    putCandidato, 
    candidatoAprovado, 
    updateCandidato,
    localeCandidatoAprovado
  } = useCandidato();
  const [disabledButton, setDisabledButton] = useState(false)
  // const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    localeCandidatoAprovado()
  }, [candidato])

  function saveToExit() {
    putCandidato();
    localStorage.removeItem('candidato')
    history.push(`/`)
    window.location.reload();
  }

  function backStep() {
    if (parseInt(params.step) === 1) {
      return history.push(`/`)
    }

    if (candidato.status !== 'iniciado' && parseInt(params.step) === 22) {
      return history.push(`/confirmsteps/1`)
    }

    if (candidato.status === 'alteracoes') {
      if (parseInt(params.step) === 21) {
        history.push(`/confirmsteps/1`)
      } else {
        history.push(`/confirmsteps/21`)
      }
      return
    }

    
    if (candidato.bolsa === 'bolsa_restituivel') {
      if (parseInt(params.step) === 16 ) history.push(`/confirmsteps/5`)
      else history.push(`/confirmsteps/${parseInt(params.step) - 1}`)
    } else {
      if (parseInt(params.step) === 21) history.push(`/confirmsteps/18`)
      else
        history.push(`/confirmsteps/${parseInt(params.step) - 1}`)
    }
  }

  function nextStep() {
    const statusBloqueado = [
      'finalizado', 'deferido', 'indeferido', 
      'aguardando', "serasa", "preaprovado_bolsa",
      "preaprovado_financeiro" ]

    putCandidato()
    //
    // if (candidato.status === 'finalizado' ){
    if (statusBloqueado.includes(candidato.status)) {
      history.push(`/confirmsteps/22`)
      return
    }
    // quando nao elegivel enviar para final bolsa por enquanto
    // depois verificar se da para colocar a pagina CandidatoAprovado(false)
    // caso contrário criar uma pagina nao_elegivel
    if (candidato.status === 'nao_elegivel' ){
      history.push(`/confirmsteps/22`)
      return
    }

    if (candidato.status === 'alteracoes') {
      if (parseInt(params.step) === 21) {
        history.push(`/confirmsteps/22`)
      } else {
        history.push(`/confirmsteps/21`)
      }
      return
    }

    // if (parseInt(params.step) === 1) {
    //   if ((candidato.status !== '') || (candidato.status === null)) {
    //     updateCandidato('status', 'iniciado')
    //   }
    // }

    if (parseInt(params.step) === 4 && candidatoAprovado && !candidatoAprovado.aprovado ) {
      history.push(`/confirmsteps/21`)
      return
    }


    if (candidato.bolsa === 'bolsa_restituivel') { 
      if (parseInt(params.step) === 5) history.push(`/confirmsteps/16`)
      // else if (parseInt(params.step) === 18) history.push(`/confirmsteps/19`)
      // else if (parseInt(params.step) === 19 && candidatoAprovado.fiador_aprovado) {
      //   history.push(`/confirmsteps/21`)
      // }
      else history.push(`/confirmsteps/${parseInt(params.step) + 1}`)
    } else {
        if (parseInt(params.step) === 18) history.push(`/confirmsteps/21`)
        else //history.push(`/confirmsteps/${parseInt(params.step) + 1}`)
        history.push(`/confirmsteps/${parseInt(params.step) + 1}`)
     }
  }

  return (
    <Container>
      <Header>
        <Row>
          {/* <Icon path={mdiArrowLeft} size="24px" color={TextColor} /> */}

          <HeaderTitle>
            Inscrição para concessão de bolsas
          </HeaderTitle>
          <Logo bg={LogoBg} />
        </Row>

        <ProgressBar />

      </Header>

      <div style={{ margin: "16px 0" }}>
        {
          <div>
            {params.step === "1" && <Step01 disabledButton={setDisabledButton}/>}
            {params.step === "2" && <Step02 disabledButton={setDisabledButton}/>}
            {params.step === "3" && <Step03 disabledButton={setDisabledButton}/>}
            {params.step === "4" && <Step04 disabledButton={setDisabledButton}/>}
            {params.step === "5" && <Step05 disabledButton={setDisabledButton}/>}
            {/* {params.step === "20" && <Step20 />} */}
          </div>
        }  
        { 
         candidato && 
         candidato.bolsa === 'bolsa_social' ?
         ( <>
          {params.step === "6" && <Step06 />}
          {params.step === "7" && <Step07 />}
          {params.step === "8" && <Step08 />}
          {params.step === "9" && <Step09 />}
          {params.step === "10" && <Step10 />}
          {params.step === "11" && <Step11 />}
          {params.step === "12" && <Step12 />}
          {params.step === "13" && <Step13 />}
          {params.step === "14" && <Step14 />}
          {/* {params.step === "15" && <Step15 disabledButton={setDisabledButton}/>}  */}
          {params.step === "15" && <Step15/>}
          {params.step === "16" && <Step16 disabledButton={setDisabledButton}/>}
          {params.step === "17" && <Step17 disabledButton={setDisabledButton}/>}
          {params.step === "18" && <Step18 disabledButton={setDisabledButton}/>}
          {/* {params.step === "19" && <Step19 />} */}
          {params.step === "20" && <Step20 disabledButton={setDisabledButton}/>}
          {params.step === "21" && <Step21 disabledButton={setDisabledButton} />}
          {params.step === "22" && <Step22 disabledButton={setDisabledButton} />}
          {/* {params.step === "23" && <Step23 />} */}
          </>
          ) : ( <>
            {params.step === "16" && <Step16 disabledButton={setDisabledButton}/>}
            {params.step === "17" && <Step17 disabledButton={setDisabledButton}/>}
            {params.step === "18" && <Step18 disabledButton={setDisabledButton}/>}
            {params.step === "19" && <Step19 disabledButton={setDisabledButton}/>}
            {params.step === "20" && <Step20 disabledButton={setDisabledButton}/>}
            {params.step === "21" && <Step21 disabledButton={setDisabledButton}/>}
            {params.step === "22" && <Step22 disabledButton={setDisabledButton}/>}
            {/* {params.step === "23" && <Step23 />} */}
            </>
          )
        } 

      </div>
      { (params.step !== "22" && params.step !== "22" ) && 
        (
          <>
          <Footer>
            <Button
              id="buttonVoltar"
              name="buttonVoltar"
              type="submit"
              onClick={backStep}
            >
              Voltar
            </Button>

            <Button
              id="buttonContinuar"
              name="buttonContinuar"
              onClick={nextStep}
              //style={{ opacity: disabledButton ? 0 : 1, pointerEvents: disabledButton ? 'none' : 'auto'}}
              style={{ display: disabledButton ? 'none' : 'block' }}
              disabled={disabledButton}
            >
              Continuar
            </Button>


          </Footer>

          </>

        )
      }

    { (params.step === "22" && candidato.status === "Finalizado") ? (
       <> </>
      ) : (
        <>
      <FooterDown>
        <Button
          id="buttonSalvarEContinuar"
          name="buttonSalvarEContinuar"
          onClick={saveToExit}
        >
          Salvar e continuar depois
        </Button>

      </FooterDown>       
      
      </>)}
    </Container>
  )
}

export default ConfirmSteps;
