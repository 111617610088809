import React, { useEffect, useState } from 'react';
import { Content, Title, Row, RadioButton, Box, Autorizacao } from './styles';
import useCandidato from '../../context/CandidatoContext'

//const ConfirmSteps = ( { disabledButton } ) => {
const EscolheBolsa = ( {disabledButton} ) => {
  const {
    candidato,
    candidatoAprovado,
    localeCandidatoAprovado,
    updateCandidatoEAtributosAninhados
  } = useCandidato();
  const [porcentagem, setPorcentagem] = useState(0)
  const [hasBolsa, setHasBolsa] = useState(false)
  const [bolsa, setBolsa] = useState({})

  function fetchCandidatoAprovado() {
    localeCandidatoAprovado()
  }
  
  useEffect(() => {
    fetchCandidatoAprovado();
    if (candidato.bolsa !== null)
      setHasBolsa(true)
  }, []);

  useEffect(() => {
    if (candidato.bolsa) {
      if (candidato.bolsa === 'bolsa_social') { 
        disabledButton(false)
      }
      if (candidato.bolsa === 'bolsa_restituivel') { 
        if (!porcentagem || porcentagem === 0) {
          disabledButton(true)
        } else {
         disabledButton(false)
        }
      }
     } else {
       disabledButton(true)
     }
  }, [hasBolsa, porcentagem])


  function handleChange(tipo_bolsa) {
    if (tipo_bolsa === 'bolsa_social') {
      const oldBolsaRestituivel = candidato.bolsa_restituiveis_attributes[0]
      setHasBolsa(true)
      updateCandidatoEAtributosAninhados({
        'bolsa': tipo_bolsa,
        //'status': 'EscolheBolsa',
        'bolsa_sociais_attributes': updateBolsaSocial(),
        'bolsa_restituiveis_attributes': [{ ...oldBolsaRestituivel, _destroy: true, status: 'Em Analise' }],
      })
    } else if (tipo_bolsa === 'bolsa_restituivel') {
      const oldBolsaSocial = candidato.bolsa_sociais_attributes[0]
      setHasBolsa(false)
      updateCandidatoEAtributosAninhados({
        'bolsa': tipo_bolsa,
        //'status': 'EscolheBolsa',
        'bolsa_sociais_attributes': [{ ...oldBolsaSocial, _destroy: true, status: 'Em Analise' }],
        'bolsa_restituiveis_attributes': updateBolsaRestituivel()
      })
    }
  }

  function updateBolsaSocial() {
    if (candidato.bolsa_sociais_attributes.length > 0) {
      const oldBolsaSocial = candidato.bolsa_sociais_attributes[0]
      const newBolsaSocial = { ...oldBolsaSocial, percentual_pretendido: candidatoAprovado.bolsa_social_percentual, status: 'Em Analise', _destroy: false }
      return [newBolsaSocial]
      
    } else {
      const newBolsaSocial = { id: null, percentual_pretendido: candidatoAprovado.bolsa_social_percentual, status: "Em Analise" }
      return [newBolsaSocial]
    }
  }

  function updateBolsaRestituivel() {
    if (candidato.bolsa_restituiveis_attributes.length > 0) {
      const oldBolsaRestituivel = candidato.bolsa_restituiveis_attributes[0]
      const newBolsaRestituivel = { ...oldBolsaRestituivel, percentual_pretendido: porcentagem, status: 'Em Analise', _destroy: false }
      return [newBolsaRestituivel]
    } else {
      const newBolsaRestituivel = { id: null, percentual_pretendido: porcentagem, status: "Em Analise" }
      return [newBolsaRestituivel]
    }
  }

  function handlePercentual(percentual) {
    const oldBolsaSocial = candidato.bolsa_sociais_attributes[0]
    setPorcentagem(percentual)
    const oldBolsaRestituivel = candidato.bolsa_restituiveis_attributes[0]
    setHasBolsa(true)
    updateCandidatoEAtributosAninhados({
      //'status': 'EscolheBolsaRestituivel',
      'bolsa_sociais_attributes': [{ ...oldBolsaSocial, _destroy: true, status: 'Em Analise' }],
      'bolsa_restituiveis_attributes': [{ ...oldBolsaRestituivel, percentual_pretendido: percentual, status: 'Em Analise' }]
  })
  }

  useEffect(() => {
    const oldBolsaSocial = candidato.bolsa_sociais_attributes[0]
    const oldBolsaRestituivel = candidato.bolsa_restituiveis_attributes[0]
    updateCandidatoEAtributosAninhados({
      //'status': 'EscolheBolsaRestituivel',
      'bolsa_sociais_attributes': [{ ...oldBolsaSocial, _destroy: true, status: 'Em Analise' }],
      'bolsa_restituiveis_attributes': [{ ...oldBolsaRestituivel, percentual_pretendido: porcentagem, status: 'Em Analise' }]
    })
    
  }, [porcentagem])

  useEffect(() => {

    if (candidato.bolsa === 'bolsa_social') {
      setBolsa(candidato.bolsa_sociais_attributes[0])
      setPorcentagem(candidato.bolsa_sociais_attributes.length > 0 ?
        candidato.bolsa_sociais_attributes[0].percentual_pretendido : 0)
    } else if (candidato.bolsa === 'bolsa_restituivel') {
       setBolsa(candidato.bolsa_restituiveis_attributes[0])
       setPorcentagem(candidato.bolsa_restituiveis_attributes.length > 0 ?
         candidato.bolsa_restituiveis_attributes[0].percentual_pretendido : 0)
      //setBolsa(candidato.bolsa_restituiveis_attributes[0])
      //setPorcentagem(candidato.bolsa_restituiveis_attributes.percentual_pretendido)
    }
    //console.log('candidato', candidato)
  }, [])    
  
  function renderPorcentagem(name, checked) {
    if (name === 'bolsa_restituivel') {
      return (
        <ul className={checked ? 'show' : ''}>
          {candidatoAprovado.bolsa_restituivel_percentual.map((item, key) => {
            return (
              <li
                key={key}
                className={parseInt(porcentagem) === item.percentual ? 'selected' : ''}
                //onClick={() => setPorcentagem(item.percentual)}
                onClick={() => handlePercentual(item.percentual)}
              >
                {item.percentual}%
              </li>
            )
          }
          )}
        </ul>
      )
    }
  }


  function renderAutorizacao() {
    
    // if (hasBolsa) {}

      if (candidato.bolsa === 'bolsa_social') {
        return (
          <span>
            <p>Bolsa Social</p>
            <div style={{height: '140px'}}>
              Modalidade de bolsa de estudo baseada na análise socioeconômica familiar 
              do candidato e no seu desempenho no vestibular. Os descontos serão de 30%, 50% ou 70%, 
              de acordo com os rendimentos mensais do grupo familiar.
            </div>
          </span>
        )
      } else {
        return (
          <span>
            <p>Bolsa Restituível</p>
            <div style={{height: '140px'}}>
              Programa disponível em número limitado, a critério da instituição, disponível 
              apenas para os processos seletivos VESTIBULAR e ENEM, que possibilita o pagamento do 
              curso em até 8 anos e SEM JUROS.
            </div>
          </span>
        )
      
    } 

  }
  
  function renderLabel(name) {
    switch (name) {
      case "bolsa_social":
        return (
          <div>
            <label htmlFor={name}>Bolsa Social</label>
            <p>
              {candidatoAprovado.bolsa_social_percentual} %
            </p>
          </div>
        )
      case "bolsa_restituivel":
        return (
          <div>
            <label htmlFor={name}>Bolsa Restituível</label>
          </div>
        )
      default:
        return null;
    }
  }
  
  return (
    <>
      <Content >
        <Title>Escolha tipo de Bolsa</Title>
        <Row style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <div>
            { candidatoAprovado &&
              candidatoAprovado.bolsas.map((tipo_bolsa, key) => {
                return (
                  <>
                    <div style={{height: '80px'}}>

                    <Box key={key} checked={candidato.bolsa === tipo_bolsa} onClick={() => handleChange(tipo_bolsa)}>
                      <RadioButton
                        type="radio"
                        id={tipo_bolsa}
                        name="bolsa"
                        checked={candidato.bolsa === tipo_bolsa}
                        onChange={() => handleChange(tipo_bolsa)}
                      />
                      
                      { renderLabel(tipo_bolsa)}
                      {renderPorcentagem(tipo_bolsa, candidato.bolsa === tipo_bolsa)}
                    </Box>

                    </div>
                  </>
                )
              })
            }
          </div>
          { (candidato.bolsa !== null) &&
          <Autorizacao>
            { renderAutorizacao()}
          </Autorizacao>
          }
        </Row>

      </Content >
    </>
  )
}

export default EscolheBolsa;
