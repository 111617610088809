import styled from "styled-components";
import {TextColor, TextButton, Bordor} from '../../util/constants'

export const Title = styled.h1`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;  
`;

export const Texto = styled.p`
  margin-bottom: 24px;  
`;

export const Button = styled.button`
  flex: 1;
  //display: block;
  height: 50px;
  border-radius: 5px;
  border: none;
  color: ${TextButton};
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background: #4D5864;
  cursor: pointer;
  background: ${Bordor};
  width: 100%;
`;