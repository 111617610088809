import React, { useEffect, useState } from 'react';
import useCandidato from '../../context/CandidatoContext';
import { Row, Strong, Select, Input, Content, Label } from './styles';

const DadosPessoais = ({ disabledButton }) => {
  const { candidato, updateCandidato } = useCandidato();
  const [dadosPessoais, setDadosPessoais] = useState({id: "", reside: "", tipo_residencia: ""});

  useEffect(() => {
  }, [candidato])

  function getDadosPessoais() {
    //updateStatusCandidato('DadosPessoais')
    if (candidato.dados_pessoais_attributes.length > 0) {
      setDadosPessoais(candidato.dados_pessoais_attributes[0]);
    }
  }

  useEffect(() => {
    getDadosPessoais();
    disabledButton(false);
  }, [])

  function setAll(params) {
    const newDadosPessoais = params
    setDadosPessoais( newDadosPessoais )
    updateCandidato('dados_pessoais_attributes', [newDadosPessoais])
  }

  return (
    <>
      <Strong>Informar dados adicionais do candidato</Strong>
      { 
      <Content >
        <Row>
          <div style={{ flex: "0 0 220px" }}>
            <Label>Com quem você reside</Label>
            <Select
              style={{ width: '100%' }}
              value={dadosPessoais.reside}
              onChange={(event) => setAll({ ...dadosPessoais, reside: event.target.value })}
            >
              <option selected value="familia">Família</option>
              <option value="outros_parentes">Outros parentes</option>
              <option value="sozinho"> Sozinho</option>
              <option value="com_amigos">Com amigos</option>
              <option value="pensao">Pensão</option>
            </Select>
          </div>
        </Row>

        {
          candidato.dados_pessoais_attributes[0].reside !== 'pensao' && (
            <Row>
              <div style={{ flex: "0 0 220px" }}>
                <Label>Informe o tipo de residência</Label>
                <Select
                  style={{ width: '100%' }}
                  value={dadosPessoais.tipo_residencia}
                  onChange={(event) => setAll({ ...dadosPessoais, tipo_residencia: event.target.value })}
                >
                  <option selected value="propria">Própria</option>
                  <option value="alugada">Alugada</option>
                  <option value="financiada">Financiada</option>
                  <option value="cedida">Cedida</option>
                </Select>
              </div>
            </Row>
          )
        }
          {/* { candidato.dados_pessoais_attributes.length > 0 &&
            (candidato.dados_pessoais_attributes[0].tipo_residencia === 'Alugada' ||
              candidato.dados_pessoais_attributes[0].tipo_residencia === 'Financiada'
            ) && (
              <div style={{ marginLeft: '16px', flex: 1 }}>
                <Label>Valor</Label>
                <Input
                  id={`dadosPessoaisResidenciaValor`}
                  placeholder="0,00"
                  value={dadosPessoais.valor}
                  onChange={(event) => setAll({ ...dadosPessoais, valor: event.target.value })}
                />
              </div>
            )
          } */}

     
      </Content>
      }
    </>
  )
}

export default DadosPessoais;
