import React, { useEffect, useState } from 'react';
import useCandidato from '../../context/CandidatoContext';
import { Content, Title, Input, Select, Row, Label, DateInput, CurrencyInput } from './styles';
import { currencyConfig } from '../../util/constants';

const DadosFiador = ( {disabledButton} ) => {
  const { candidato, updateCandidato } = useCandidato();
  const [fiador, setFiador] = useState(
    {
    id: 0,
    situacao_profissional: "empregado", 
    renda_bruta: 0,
    nome: '',
    cpf: '',
    rg: '',
    data_nascimento: '',
    telefone_fixo: '',
    telefone_celular: '',
    email: ''
  })
  // const [fiador, setFiador] = useState({})
  const [rendaBrutaEmpresa, setRendaBrutaEmpresa] = useState(0)
  
  useEffect(() => {
    setFiador(candidato.fiadores_attributes[0]);
    setRendaBrutaEmpresa(Number(candidato.fiadores_attributes[0].renda_bruta))
    // disabledButton(true)
  }, [])

  useEffect(() => {
    disabledButton(!validaCampos())
  }, [fiador])

  function validaCampos() {
    let result = true
      
    if (fiador.situacao_profissional === '' 
      || (fiador.nome === '' || fiador.nome === null)
      || (fiador.cpf === '' || fiador.cpf === null)
      || (fiador.rg === ''  || fiador.rg === null)
      || (fiador.data_nascimento === ''  || fiador.data_nascimento === null)
      || (fiador.telefone_fixo === ''    || fiador.telefone_fixo === null)
      || (fiador.telefone_celular === '' || fiador.telefone_celular === null)
      || (fiador.email === '' || fiador.email === null)
      || (fiador.renda_bruta === '' )
      || fiador.renda_bruta === 0  
      ) {
      result = false
    }
    return result
  }

  function setAll(params) {
    const newFiador = params
    setFiador( newFiador )
    updateCandidato('fiadores_attributes', [newFiador])
  }

  const handleRendaBrutaEmpresa = (event, value, maskedValue) => {
    const newFiador = { ...fiador, renda_bruta: value }
    setRendaBrutaEmpresa(value);
    setFiador({ ...fiador, renda_bruta: value })
    updateCandidato('fiadores_attributes', [newFiador])    
  };  

  return (
    <>
      <Title>Informar dados do fiador</Title>
      
      {
        
        fiador && (
        <>
          <Row>
            <div style={{ flex: "0 0 100%" }}>
              <Label>Nome Completo</Label>
              <Input
                id={`fiadorNomeCompleto`}
                placeholder="Nome Completo"
                value={fiador.nome}
                onChange={(event) => setAll({ ...fiador, nome: event.target.value })}
              />
            </div>
          </Row>

          <Row>
            <div style={{ flex: 1 }}>
              <Label>CPF</Label>
              <Input
                id={`fiadorCpf`}
                placeholder="CPF"
                value={fiador.cpf}
                onChange={(event) => setAll({ ...fiador, cpf: event.target.value })}
                mask="999.999.999-99"
              />
            </div>

            <div style={{ flex: 1, marginLeft: '16px' }}>
              <Label>RG</Label>
              <Input
                id={`fiadorRg`}
                placeholder="RG"
                value={fiador.rg}
                onChange={(event) => setAll({ ...fiador, rg: event.target.value })}
              />
            </div>

            <div style={{ flex: 1, marginLeft: '16px' }}>
              <Label htmlFor="dataNascimento">Data Nascimento</Label>
                <DateInput
                  id={`fiadorDataNascimento`}
                  type="date"
                  placeholder="00/00/0000"
                  value={fiador.data_nascimento}
                  onChange={(event) => setAll({ ...fiador, data_nascimento: event.target.value })}
                />
              </div>
          </Row>


          <Row>
            <div style={{ flex: 1 }}>
              <Label>Telefone Fixo</Label>
              <Input
                id={`fiadorTelefoneFixo`}
                placeholder="DDD 999999999"
                value={fiador.telefone_fixo}
                onChange={(event) => setAll({ ...fiador, telefone_fixo: event.target.value })}
                mask="(99) 9999.9999"
              />
            </div>

            <div style={{ flex: 1, marginLeft: '16px' }}>
              <Label>Telefone Celular</Label>
              <Input
                id={`fiadorTelefoneCelular`}
                placeholder="DDD 999999999"
                value={fiador.telefone_celular}
                onChange={(event) => setAll({ ...fiador, telefone_celular: event.target.value })}
                mask="(99)9.9999.9999"
              />
            </div>

            <div style={{ flex: 2, marginLeft: '16px' }}>
              <Label>E-mail</Label>
              <Input
                id={`fiadorEmail`}
                placeholder="joao@espm.br"
                value={fiador.email}
                onChange={(event) => setAll({ ...fiador, email: event.target.value })}
              />
            </div>
          </Row>

          <Row>
            <div style={{ flex: "0 0 248px" }}>
            <Label>Situação Profissional</Label>
            <Select
              style={{ width: '100%' }}
              value={fiador.situacao_profissional}
              onChange={(event) => setAll({ ...fiador, situacao_profissional: event.target.value })}
            >
              <option selected value="empregado">Empregado</option>
              <option value="aposentado">Aposentado</option>
              <option value="autonomo">Autônomo</option>
            </Select>
            </div>

            {
                fiador && fiador.situacao_profissional === 'empregado' && (
                  <>
                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Empresa</Label>
                    <Input
                      id={`fiadorEmpresa`}
                      placeholder="nome da empresa"
                      value={fiador.empresa}
                      onChange={(event) => setAll({ ...fiador, empresa: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label htmlFor="valorBrutoMensal">Renda Mensal</Label>
                    <CurrencyInput  
                      id="valorBrutoMensal"
                      name="valorBrutoMensal"
                      className="currency-input"
                      currency="BRL" config={currencyConfig()}
                      type="text"
                      placeholder="0.00"  
                      decimalSeparator="," 
                      groupSeparator="."
                      value={rendaBrutaEmpresa}
                      onChange={handleRendaBrutaEmpresa}
                    />
                  </div>

                  </>
                )
            }

            {
              fiador && fiador.situacao_profissional === 'aposentado' && (
                <>
                  <div style={{ flex: '0 0 248px', marginLeft: '16px' }}>
                    <Label htmlFor="valorBrutoMensal">Renda Mensal</Label>
                    <CurrencyInput  
                      className="currency-input"
                      currency="BRL" config={currencyConfig()}
                      type="text"
                      placeholder="0.00"  
                      decimalSeparator="," 
                      groupSeparator="."
                      value={rendaBrutaEmpresa}
                      onChange={handleRendaBrutaEmpresa}
                    />
                  </div>
                </>
              )
            }

            {
              fiador && fiador.situacao_profissional === 'autonomo' && (
                <>
                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label>Qual Atividade</Label>
                    <Input
                      id={`fiadorEmpresa`}
                      placeholder="Atividade principal"
                      value={fiador.autonomo_atividade}
                      onChange={(event) => setAll({ ...fiador, autonomo_atividade: event.target.value })}
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: '16px' }}>
                    <Label htmlFor="valorBrutoMensal">Renda Mensal</Label>
                    <CurrencyInput  
                      className="currency-input"
                      currency="BRL" config={currencyConfig()}
                      type="text"
                      placeholder="0.00"  
                      decimalSeparator="," 
                      groupSeparator="."
                      value={rendaBrutaEmpresa}
                      onChange={handleRendaBrutaEmpresa}
                    />
                  </div>

                </>
              )
            }

          </Row>



        </>)
      }
    </>
  )
}

export default DadosFiador;
