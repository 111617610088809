import React from 'react'

const Whatsapp = () => {
  return (
    <>
      <a
        href="https://wa.me/558006073777"
        rel="noreferrer"
        target="_blank">
        Precisa de ajuda?
      </a>
    </>
  )
}

export default Whatsapp
