import styled from "styled-components";
import { Bordor } from "../../util/constants";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`

  width: 100%;
  background: #ccc;

  border-radius: 5px;
  
  th {
    padding: 16px 8px;
    background: ${Bordor} ;
    color: white;
    &:first-of-type {
      border-top-left-radius: 5px; 
    }
    &:last-of-type {
      border-top-right-radius: 5px; 
    }
  }

  tbody {
    tr {
      cursor: pointer;
      transition: all 0.15s ease;

      &:hover {
        background: #d9d9d9;
      }
      &:nth-of-type(even) {
        background: #e9e9e9;
        &:hover {
        background: #d9d9d9;
      }
      }
    }

    tr > td {
      padding: 16px 8px;
      &.alignLeft {
        text-align: left;
      }
      &.alignRight {
        text-align: right;
      } 
      &.alignCenter {
        text-align: center;
      }
    }
  }
`;


