import React, { useState, useEffect, Fragment } from 'react'
import { Title, Label, Input, Button, Text, Row, Col, Form, Image, ErrorMessage, Strong, DataCol, Logo } from './styles';
import backgroundImage from '../../assets/bg.png';
import useCandidato from '../../context/CandidatoContext';
import { useHistory } from 'react-router-dom';
import Logobg from '../../assets/logo.png';
import Whatsapp from '../../components/Whatsapp';

const Home = () => {
  const history = useHistory();
  const [cpf, setCpf] = useState('') 
  const [phone, setPhone] = useState('')
  const [showError, setShowError] = useState(false);
  const [showErrorPhone, setShowErrorPhone] = useState();
  const { 
    candidato, 
    localeCandidato, 
    comparePhone, 
    loading, 
    updateLoading, 
    error } = useCandidato();
  

  useEffect(() => {
    if (error) {
      setShowError(true)
      setTimeout(() => {
        updateLoading(false);
        setShowError(false);
      }, 3000)
    }
  }, [error])

  useEffect(() => {
    if (showErrorPhone) {
      setShowError(true) 
      setTimeout(() => {
        setShowError(false);
        setShowErrorPhone();
        updateLoading(false)
      }, 3000)
    }
  }, [showErrorPhone])

  
  const handleCpf = (event) => {
    setCpf(event.target.value)
  }
  
  const handlePhone = (event) => {
    setPhone(event.target.value)
  }

  const handleGetCPF = (event) => {
    event.preventDefault();
    localeCandidato(cpf)
  }
 
  const handleGetPhone = (event) => {
    event.preventDefault();
    const newPhone = phone.replace(/[^a-z0-9]/gi, '')
    nextStep()
  }

  function nextStep() {
    const phoneCandidato = candidato.numero_celular.replace(/[^a-z0-9]/gi, '')
    const newPhone = phone.replace(/[^a-z0-9]/gi, '')
    if (newPhone !== phoneCandidato) {
      updateLoading(true)
      setShowErrorPhone('Telefone invalido!')
      return
    } else {
      history.push('/confirmsteps/1');
    }
  }

  function formatRG(rg) {
    let initRg = rg.substring(0, 2)
    let endRg = rg.substring(rg.length - 2, rg.length)
    let numberChar = rg.length;
    let characters = '';
    for (var i = 0; i < numberChar; i++) {
      characters = characters + '*';
    }
    return initRg + characters + endRg;
  }

  function formataName(name) {
    let initName = name.substring(0, 2)
    let endName = name.substring(name.length - 3, name.length)
    let numberChar = name.length - 6
    let characters = "" //* numberChar
    for (var i = 0; i < numberChar; i++) {
      characters = characters + '*'
    }
    return initName + characters + endName;
  }

  function formatPhone(phone) {
    let dddPhone = ('(**) ')
    let initPhone = phone.substring(4, 6)
    let endPhone = phone.substring(13, 15)

    return dddPhone + initPhone + '***-**' + endPhone;
  }

  return (
    <Row>
      <Col>
        <Logo bg={Logobg}/>
        <Title>Inscrição para concessão de bolsas</Title>
        {candidato ?

          <Fragment>
            <Strong>Dados encontrados</Strong>
            <div style={{ display: 'flex', alignItems: 'center', margin: "15px 0" }}>
              <DataCol >
                <Strong>Nome:</Strong>    
                <Strong>RG:</Strong>    
                <Strong>Telefone:</Strong>    
              </DataCol>

              <DataCol >
                <Text>{formataName(candidato.nome)}</Text>
                <Text>{formatRG(candidato.rg)}</Text>
                <Text>{formatPhone(candidato.numero_celular)}</Text>
              </DataCol>
              </div>
            <Strong>Se você reconhece os dados acima, informe abaixo o seu número de telefone para prosseguir.</Strong>

            <Form onSubmit={handleGetPhone}>
              <Label htmlFor="cpf">Informe o número de telefone do candidato</Label>
              <Input
                placeholder="(DD)98714.9800"
                value={phone}
                onChange={handlePhone}
                required={true}
                autoFocus
                mask="(99)9.9999.9999"
              />
              
              {showErrorPhone && showError &&
                <ErrorMessage>{showErrorPhone}</ErrorMessage>
              }

              {/* <Button type='submit' >{loading ? 'Carregando' : 'Buscar'}</Button> */}
              <Button type='submit'>{loading ? 'Carregando' : 'Continuar'}</Button>
              

            </Form>

          </Fragment>
          :
          <Fragment>

            <Form onSubmit={handleGetCPF}>
              <Label htmlFor="cpf">Informe o cpf do candidato</Label>
              <Input
                placeholder="123.456.789-00"
                value={cpf}
                onChange={handleCpf}
                required={true}
                mask="999.999.999-99"
              />
             
              {error && showError &&
                <ErrorMessage>{error}</ErrorMessage>
              }

              <Button type='submit'>{loading ? 'Carregando' : 'Buscar'}</Button>

            </Form>

            <Text>
              <Whatsapp />
            </Text>
          </Fragment>


        }

      </Col>

      <Col>
        <Image bg={backgroundImage} />
      </Col>
    </Row>
  )
}

export default Home;
