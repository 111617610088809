import styled from "styled-components";
import { Bordor, TextColor, TextButton } from "../../util/constants";
import NumberFormat from 'react-number-format';
import IntlCurrencyInput from "react-intl-currency-input";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 768px;
  
  color: black;
`;

export const TotalLabel = styled.span`
  margin-right: 16px;
  height: 50px;
  padding: 0 8px;

  background: ${Bordor};
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
`;

export const Input = styled.input`
-webkit-appearance: none;
appearance: none;

background: white;

border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;

`;

export const DateInput = styled.input.attrs({ type: 'date' })`
-webkit-appearance: none;
appearance: none;

background: white;

border-radius: 5px;
border: none;
font-family: 'Manrope', sans-serif;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;

`;

export const Select = styled.select`
padding-right: 16px;
background: ${TextButton};
border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin: 0px 16px 16px 0;

width: 200px;

`;

export const CurrencyInput = styled(IntlCurrencyInput)`
  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;
  width: 100%;
`;

export const Number = styled(NumberFormat)`
  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;
  width: 100%;
`;

export const ButtonModal = styled.button`
  align-self: flex-start;

  width: 50px;
  height: 50px;
  margin-bottom: 8px;

  border-radius: 5px;
  border: none;

  font-weight: 600;
  font-size: 18px;
  text-align: center;

  background: ${Bordor};
  color: white;

  cursor: pointer;

`;

export const Label = styled.label`
  display: block;
  color: ${TextColor};
  margin-bottom: 8px;
  font-weight: 600;
  > span + div {
    margin-top: 8px;
  }
`;

export const Strong = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

export const Button = styled.button`
  flex: 1;
  height: 50px;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background: #4D5864;
  cursor: pointer;
 
  &:last-of-type {
    background: ${Bordor};
    margin-left: 32px;
  }

  opacity: 0.8;
  transition: all 0.15s ease;

  &:hover {
    opacity: 1;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

`;

