import styled from "styled-components";
import { TextColor, TextButton, Bordor } from "../../util/constants";

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 80px;
  height: 100%;
  /* transform: translate(-50%, -50%); */

  max-width: 1028px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.p`
  font-size: 24px;
  color: ${TextColor};
  font-weight: 700;
  margin-left: 8px;
`;

export const ProgressBar = styled.div`
  position: relative;
  /* flex: 0 0 100%; */
  height: 4px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);

  &::before {
    content: '';
    position: absolute;
    background: ${TextColor};
    border-radius: 2px;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-of-type{
    align-items: flex-end;
    margin-right: 10px;
    }
`;

export const Strong = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 700;

`;

export const Text = styled.span`
  display: inline;
  color: ${TextColor};
  margin-bottom: 16px;
  font-weight: 400;
`;

export const Content = styled.div`
//   flex: 1;
//   /* display: flex;
//   flex-direction: column;
//   align-items: flex-start; */

`;

export const Footer = styled.div`
  display: flex;
  align-items: center;

`;

export const FooterDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-left: -32px;
`;


export const Button = styled.button`
  flex: 1;
  height: 50px;
  border-radius: 5px;
  border: none;
  color: ${TextButton};
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background: #4D5864;
  cursor: pointer;

  &:last-of-type {
    background: ${Bordor};
    margin-left: 32px;
  }
`;

export const Logo = styled.div`
  width: 97px;
  height: 37px;
  background: ${props => `url(${props.bg})` };
  background-size: cover;
  background-position: center;
  margin-left: auto;
`;