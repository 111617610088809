import styled from "styled-components";
import { TextColor, Bordor, TextButton } from "../../util/constants";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 14px;

  > p { 
    margin-top: 8px;
    line-height: 1.5;
    
    div {

    }
  }

  ul {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  ul li {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.5;
  }
`;

export const Title = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;

export const Button = styled.button`
  height: 50px;
  border-radius: 5px;
  border: none;
  color: ${TextButton};
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  background: ${Bordor};
  width: 100%;
  margin-top: 16px;
`;

