import React, { useEffect, useState } from 'react';


import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import {Container, Overlay, Modal, ButtonCloseModal} from './styles';
import { TextColor } from "../../util/constants";

const ModalSteps = ({ children, show, onClose }) => {
  const [showModal, setShowModal] = useState(show || false);

  useEffect(() => {
    if (show) {
      setShowModal(true)
    }
    else {
      closeModal();
    }
  }, [show])

  function closeModal() {
    setShowModal(false)
    onClose();

  }


  return (
    <Container className={showModal ? "show" : ""}> 
      <Overlay />
  
      <Modal>
        <ButtonCloseModal onClick={closeModal}>
          <Icon path={mdiClose} size="24px" color={TextColor} /> 
        </ButtonCloseModal>
        {children}
      </Modal>
    </Container>
  )
}

export default ModalSteps
