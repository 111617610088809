// https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js
import React, { useEffect, useState } from 'react';
import useCandidato from '../../context/CandidatoContext';
import { Input, Label, Strong, CurrencyInput } from './styles';

const OutrasReceitas = ( {disabledButton} ) => {
  const { candidato, updateCandidatoEAtributosAninhados } = useCandidato();
  const [rendaBruta, setRendaBruta] = useState();
  const [outrasReceitas, setOutrasReceitas] = useState({})

  const currencyConfig = {
      locale: "pt-BR",
      formats: {
        number: {
          BRL: {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          },
        },
      },
    };  

  function getOutrasReceitas() {
    if(candidato.outras_receitas_attributes.length > 0) {
      setRendaBruta(Number(candidato.outras_receitas_attributes[0].valor))
      setOutrasReceitas(candidato.outras_receitas_attributes[0]); 
    }
  }
 
  useEffect(() => {
    getOutrasReceitas();
    disabledButton(false)
  }, [])

  function setAll(params) {
    const newOutrasReceitas = params
//    const currentCadidato = {...candidato, outras_receitas_attributes: [newOutrasReceitas] }
    setOutrasReceitas(  newOutrasReceitas )
    //setCandidato(currentCadidato) 
    //updateCandidato('outras_receitas_attributes', newOutrasReceitas)
    updateCandidatoEAtributosAninhados({
      'outras_receitas_attributes': [newOutrasReceitas]
    })
  }

  const handleValueChange = (event, value, maskedValue) => {
    const newOutrasReceitas = { ...outrasReceitas, valor: value }
    //event.preventDefault();
    // console.log(value); // value without mask (ex: 1234.56)
    // console.log(maskedValue); // masked value (ex: R$1234,56)
    setRendaBruta(value);
    //setOutrasReceitas({ ...outrasReceitas, valor: value })
    //updateCandidato('outras_receitas_attributes', { ...outrasReceitas, valor: value })
    updateCandidatoEAtributosAninhados({
      'outras_receitas_attributes': [newOutrasReceitas]
    })
  };  


  return (
    <>
      <Strong>Informar outras rendas</Strong>

      <Label htmlFor="outrasReceitasDescricao">
        Especifique caso exista outra renda familiar
      </Label>
      <Input
        id="outrasReceitasDescricao"
        placeholder="Especifique caso exista outra renda familiar"
        value={outrasReceitas.descricao}
        onChange={(event) => setAll({ ...outrasReceitas, descricao: event.target.value })}
        requied
      />
      <Label htmlFor="outrasReceitasValor">
        Informe o valor da renda
      </Label>
      {/* <Number
        id="outrasReceitasValor"
        displayType="Rendimento bruto mensal"
        thousandSeparator={false}
        prefix={''}
        value={outrasReceitas.valor}
        onChange={(event) =>  setAll({ ...outrasReceitas, valor: event.target.value })}
        
        required
      />     */}
      <CurrencyInput  
        className="currency-input"
        currency="BRL" config={currencyConfig}
        type="text"
        placeholder="0.00"  
        decimalSeparator="," 
        groupSeparator="."
        value={rendaBruta}
        onChange={handleValueChange}
        //onValueChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, valor: event.currentTarget.value  })}
      />

    </>
  )
}

export default OutrasReceitas;
