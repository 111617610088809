import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';

import  useCandidato from '../../context/CandidatoContext'
import { Gray } from '../../util/constants';
import Whatsapp from '../Whatsapp';
import TextoCandidatoNaoAprovado from '../TextoCandidatoNaoAprovado';
import {Title, Texto, Button} from './style';

const TextGeneric = () => {
  return (
    <Texto style={{fontSize: '20px', fontWeight: 600 }}>
      As informações e a documentação para sua participação neste processo de concessão de bolsa estão completas.
      Agora falta apenas a divulgação do resultado dos processos seletivos.
    </Texto>

  )
}


const FinalBolsa = ( { disabledButton } ) => {
  const history = useHistory();
  const params = useParams();
  const { candidato, putCandidatoFinalStatus } = useCandidato();

  function handleHome() {
    putCandidatoFinalStatus('finalizado');   
    history.push(`/`);
    localStorage.removeItem('candidato');
    window.location.reload();
    
   }

  function back() {
    //history.push(`/confirmsteps/20`)
    //history.push(`/confirmsteps/${parseInt(params.step) - 1}`)    
    if (candidato.status !== 'iniciado' && parseInt(params.step) === 22) {
      return history.push(`/confirmsteps/1`)
    } else {
      return history.push(`/confirmsteps/${parseInt(params.step) - 1}`)    
    }
                                                                                                                                                
  }

  useEffect(() => {
    disabledButton(false)
  }, [])

  return (
    <>
      {/* <h2>{candidato.status}</h2> */}
      {/* <Title>Aguardando instruções da equipe da bolsa.</Title> */}
      { candidato && (
        candidato.status === "aguardando" ||
        candidato.status === "serasa" ||
        candidato.status === "preaprovado_bolsa" ||
        candidato.status === "preaprovado_financeiro" ||
        candidato.status === "deferido" ||
        candidato.status === "desistencia" 
      )
       && 
        <>
          {/* <Texto style={{fontSize: '24px'}}>As informações e a documentação para sua participação neste processo de concessão de bolsa estão completas.</Texto>
          <Texto>Agora falta apenas a divulgação do resultado dos processos seletivos.</Texto> */}
          <TextGeneric />
        </>
      }

      { candidato && (candidato.status === 'indeferido') &&
        <>
          <TextoCandidatoNaoAprovado />
        </>
      }

      { candidato && candidato.status === "finalizado" &&
      
          <>
            <TextGeneric />

            <Texto>
              <Whatsapp />
            </Texto> 

            <Button onClick={handleHome}>
              Fechar
            </Button>
          </>
        
      // ) : (
    }
    { candidato && (candidato.status === "iniciado" || candidato.status === "alteracoes") &&
        <>
          <Texto style={{fontSize: '20px', fontWeight: 600 }}>
            Você preencheu todos os dados necessários para a análise do seu 
            pedido de bolsa. Ao clicar em “Finalizar e enviar para análise” 
            você não terá mais acesso aos dados inseridos. 
            Portanto, se preferir, poderá retornar para revisá-los. Quanto mais completas as 
            informações fornecidas, mais rápida será a análise de seu pedido.
          </Texto>


          <Button 
          style={{marginBottom: '16px', background: Gray}}
          onClick={back}
          >
          Voltar
          </Button>

          <Button onClick={handleHome}
          >
          Finalizar e enviar para analise
          </Button>
        </>
      }
      
    </>
  )
}

export default FinalBolsa;
