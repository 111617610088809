import styled from "styled-components";

export const Content = styled.div`

  display: flex;
  flex-direction: column;

  margin-bottom: 14px;

  > p { 
    margin-top: 8px;
    line-height: 1.5;
    
    div {

    }
  }

  ul {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  ul li {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.5;
  }
`;