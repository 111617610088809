import React, { useEffect, useState } from 'react';
import useCandidato from '../../context/CandidatoContext';
import TipoDespesasService from '../../api/tipo_despesas';
import { Content, Title, Input, Strong, CurrencyInput } from './styles';
import { currencyConfig } from '../../util/constants';

const DespesasSeguro = () => {
  const despesa = 5
  //const [candidato, setCandidato] = useState(JSON.parse(localStorage.getItem("candidato") || "{}" ))
  const { candidato, updateCandidato, updateCandidatoEAtributosAninhados } = useCandidato();
  const [tipoDespesa, setTipoDespesas] = useState([]);
  const [despesas, setDespesas] = useState([]);
  const [listDespesas, setListDespesas] = useState([])
  const [despesaCandidato, setDespesaCandidato] = useState({ 
    id: 0,
    uuid: null, 
    tipo_despesa_id: null, 
    despesa_id: null, 
    valor: 0.00, 
    observacao: ""
  })
  
  async function fetchTipoDespesas() {
    const response = await TipoDespesasService.show(despesa);
    setTipoDespesas(response.data.tipo_despesa)
    setDespesas(candidato.candidato_despesas_attributes.filter(item => item.tipo_despesa_id === despesa))
  }
  
  useEffect(() => {
    fetchTipoDespesas();
  }, []);

  useEffect(() => {
    setListDespesas(candidato.candidato_despesas_attributes)
  }, [listDespesas]) //candidato

  function setAll(params, despesa) {
    const currentListDespesa = [...listDespesas]
    const newDespesaCandidato = {
      id: despesa.id,
      tipo_despesa_id: tipoDespesa.id,
      despesa_id: despesa.id,
      valor: params.valor,
      observacao: params.observacao,
      uuid: params.uuid,
    }
    if (currentListDespesa.some( (item) => item.id === newDespesaCandidato.id) ) {
      const item = currentListDespesa.find(item => item.id === newDespesaCandidato.id);
      item.valor = newDespesaCandidato.valor 
      item.observacao = newDespesaCandidato.observacao
      setDespesaCandidato(item)
      setListDespesas(currentListDespesa); 
      //setCandidato({...candidato, candidato_despesas_attributes: currentListDespesa})
      updateCandidatoEAtributosAninhados({
        'candidato_despesas_attributes': currentListDespesa
      })
    }
  }

  const handleValueChange = (params, despesa) => {
    return (event, value, maskedValue) => {
      const currentListDespesa = [...listDespesas]
      const newDespesaCandidato = {
        id: despesa.id,
        tipo_despesa_id: tipoDespesa.id,
        despesa_id: despesa.id,
        valor: value,
        observacao: params.observacao,
        uuid: params.uuid,
      }

      if (currentListDespesa.some( (item) => item.id === newDespesaCandidato.id) ) {
        const item = currentListDespesa.find(item => item.id === newDespesaCandidato.id);
        item.valor = newDespesaCandidato.valor 
        item.observacao = newDespesaCandidato.observacao
        setDespesaCandidato(item)
        setListDespesas(currentListDespesa); 
        updateCandidatoEAtributosAninhados({
          'candidato_despesas_attributes': currentListDespesa
        })
      }
    }
  };  

  return (
    <>
      <Title>Informar despesas ({tipoDespesa.nome})</Title>

      <Content >
        {
          despesas.map((despesa, key) => 
          <div style={{flex: 1, display: 'flex', alignItems: 'center'}} key={key} >
          <Strong> {despesa.nome_despesa}: </Strong>
          {/* <Input
            id={`despesasMoradiaCondominioValor_${despesa.id}`}
            placeholder="0,00"    
            value={despesa.valor}
            onChange={(event) => setAll({ ...despesaCandidato, valor: event.target.value, observacao: despesa?.observacao }, despesa )} 
            autoComplete='off'
          /> */}

          <CurrencyInput  
            className="currency-input"
            currency="BRL" config={currencyConfig()}
            type="text"
            placeholder="0.00"  
            decimalSeparator="," 
            groupSeparator="."
            value={Number(despesa.valor)}
            onChange={handleValueChange({...despesaCandidato, valor: despesa?.valor, observacao: despesa?.observacao }, despesa)}
            //onValueChange={(event) => setGrupoFamiliar({ ...grupoFamiliar, valor: event.currentTarget.value  })}
          />   
          <Input
            id={`despesasSeguroObservacao_${despesa.id}`}
            placeholder="observacao"
            value={despesa.observacao}
            onChange={(event) => setAll({ ...despesaCandidato, observacao: event.target.value, valor: despesa?.valor }, despesa )}
            autoComplete='off'
            />
          </div>
          )} 
      </Content >

    </>
  )
}

export default DespesasSeguro;
