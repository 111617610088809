import styled from "styled-components";
import NumberFormat from 'react-number-format';
import { TextColor } from "../../util/constants";
import IntlCurrencyInput from "react-intl-currency-input";

export const Input = styled.input`
/* -webkit-appearance: none;
appearance: none; */

background: white;
border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;
`;

export const Number = styled(NumberFormat)`
  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;
  width: 100%;
`;

export const CurrencyInput = styled(IntlCurrencyInput)`
  background: white;
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 13px;
  margin-bottom: 16px;
  width: 100%;
`;

export const Select = styled.select`
-webkit-appearance: none;
appearance: none;

background: white;
border-radius: 5px;
border: none;
height: 50px;
padding: 0 13px;
margin-bottom: 16px;

width: 100%;
`;

export const Label = styled.label`
  display: block;
  color: ${TextColor};
  margin-bottom: 8px;
  font-weight: 600;
`;

export const Strong = styled.span`
  display: block;
  color: ${TextColor};
  font-weight: 700;
  font-size: 24px;

  margin-bottom: 24px;
`;
