import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '@mdi/react';
import {mdiCloudUpload} from '@mdi/js';

import useCandidato from '../../context/CandidatoContext';
import {Content, Title, Row, Label, ButtonModal} from './styles';
import {Bordor, Green} from '../../util/constants';

import api from '../../api';
import CandidatoReceitas from '../../api/receitas';
import CandidatoFiadores from '../../api/fiadores';
import Whatsapp from '../Whatsapp';

const DownloadLink = (props) => {
  return (
    <a 
      style={{color: 'white'}} 
      href={props.src} 
      target="_blank" 
      rel="noreferrer" >
      {props.children}
    </a>
  )
}

const Documentos = ( {disabledButton} ) => {
  const history = useHistory();
  const {candidato, localeCandidato} = useCandidato();
  const [receitas, setReceitas] = useState([])
  const [fiador, setFiador] = useState(null)
  const [isValid, setIsValid] = useState(false)

  async function fetchCandidatoReceitas() {
    const response = await CandidatoReceitas.index(candidato.id);
    setReceitas(response.data)
    disabledButton(!existDocumento())
    setIsValid(!existDocumento())
  }

  async function fetchCandidatoFiadores() {
    const response = await CandidatoFiadores.index(candidato.id);
    setFiador(response.data[0])
    disabledButton(!existDocumento())
    setIsValid(!existDocumento())
  }
  
  useEffect(() => {
    fetchCandidatoReceitas();     
    fetchCandidatoFiadores();     
    disabledButton(!existDocumento())
  }, [candidato, isValid, receitas, fiador])
  // , receitas, fiador

  
  function existDocumento() {
    let result = true
    if (receitas) {
      let i = 0;
      while (i < receitas.length) {
        if ( receitas[i].ir_doc             === null
          || receitas[i].residencia_doc     === null
          || receitas[i].ganhos_mensais_doc === null
          || receitas[i].doc_identificacao  === null
          ) {
          result = false
          break;
        }
        i += 1;
      }
    }
    if (fiador && fiador.nome) {
      if ( fiador.ir_doc             === null
        || fiador.residencia_doc     === null
        || fiador.ganhos_mensais_doc === null
        || fiador.doc_identificacao  === null
        ) {
        result = false
      }
    }

    return result
  }


  function uploadFileFiador(file, attribute, item) {
    const data = new FormData();
    data.append(`candidato[fiadores_attributes][${item.id}][${attribute}]`, file);
    data.append(`candidato[fiadores_attributes][${item.id}][id]`, item.id);
    api.patch(`/candidatos/${candidato.id}`, data).then(response => {
      // code
    });
    setIsValid(false)
    localeCandidato(candidato.cpf)
  }

  function uploadFileGrupoFamiliar(file, attribute, item) {
    const data = new FormData();
    data.append(`candidato[receitas_attributes][${item.id}][${attribute}]`, file);
    data.append(`candidato[receitas_attributes][${item.id}][id]`, item.id);
    api.patch(`/candidatos/${candidato.id}`, data).then(response => {
     // code
    });
    setIsValid(false)
    localeCandidato(candidato.cpf)
  }
  
  function isImage(file) {
    return (file) ? Green : Bordor
  }

  function pageFormatDocument(){
    // const  url = `/formatdocumentos/`;
    // window.open(url);
  }

  return (
    <>
     
      <Title>
        <div>
          Envio de Documentos 
          <p style={{fontWeight: '700', color: Bordor}}>
            Deverá ser enviado apenas um arquivo para cada tipo de documento solicitado, em formato pdf, jpeg ou png.
          </p>
        </div>
  
        <div>
          <ButtonModal 
            style={{marginLeft: '20px'}}
            onClick={() => pageFormatDocument()}
            title="Clique aqui, para orientação do envio da documentação." 
            >
            <DownloadLink src="/documentacao_para_solicitacao_de_bolsa_de_estudo.pdf">
              Orientações para o envio dos documentos. Clique aqui!
            </DownloadLink>
            {/* Orientações para o envio dos documentos. Clique aqui! */}
            {/* <Icon path={mdiHelp} size="24px" color="white"></Icon> */}
          </ButtonModal>
        </div>

      </Title>

      <Whatsapp />

      
      <Content>

          {
          receitas.map(receita => (
          <Row key={receita.id} style={{marginBottom: '20px'}}>
            <div style={{ flex: "0 0 30%" }}>
              {receita.nome}
            </div>

            <div style={{ flex: "1", width: '180px', textAlign: 'center' }}>
              <Label htmlFor={`candidato_doc_identificacao_${receita.id}`}>Doc. de identificação
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <Icon path={mdiCloudUpload} size="32px" color={isImage(receita.doc_identificacao)}/> 
              </span>
              <input 
                style={{ disabled: 'hidden', visibility: 'hidden'}}
                type="file"
                accept="application/pdf, image/png, image/jpg" 
                name={`candidato_doc_identificacao_${receita.id}`}
                id={`candidato_doc_identificacao_${receita.id}`}
                onChange={(event) => uploadFileGrupoFamiliar(event.target.files[0], 'doc_identificacao', receita)}
              > 
              </input>
              </Label>
            </div>
            <div style={{ flex: "1", width: '180px' }}>
              <Label htmlFor={`candidato_residencia_doc_${receita.id}`}>Comp. de Residência
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <Icon path={mdiCloudUpload} size="32px" color={isImage(receita.residencia_doc)}/> 
              </span>
              <input 
                style={{ disabled: 'hidden', visibility: 'hidden'}}
                type="file"
                accept="application/pdf, image/png, image/jpg" 
                name={`candidato_residencia_doc_${receita.id}`}
                id={`candidato_residencia_doc_${receita.id}`}
                onChange={(event) => uploadFileGrupoFamiliar(event.target.files[0], 'residencia_doc', receita)}
              > 
              </input>
              </Label>
            </div>
  
            <div style={{ flex: "1", width: '180px' }}>
              <Label htmlFor={`candidato_ganhos_mensais_doc_${receita.id}`}>Comp. Ganhos Mensais
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <Icon path={mdiCloudUpload} size="32px" color={isImage(receita.ganhos_mensais_doc)}/> 
              </span>
              <input 
                style={{ disabled: 'hidden', visibility: 'hidden'}}
                type="file"
                accept="application/pdf, image/png, image/jpg" 
                name={`candidato_ganhos_mensais_doc_${receita.id}`}
                id={`candidato_ganhos_mensais_doc_${receita.id}`}
                onChange={(event) => uploadFileGrupoFamiliar(event.target.files[0], 'ganhos_mensais_doc', receita)}
              > 
              </input>
              </Label>
            </div>
  
            <div style={{ flex: "1", width: '120px', marginLeft: '16px' }}>
            <Label htmlFor={`candidato_ir_doc_${receita.id}`}>Declaração IR
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <Icon path={mdiCloudUpload} size="32px" color={isImage(receita.ir_doc)}/> 
              </span>
              <input 
                style={{ disabled: 'hidden', visibility: 'hidden'}}
                type="file"
                accept="application/pdf, image/png, image/jpg" 
                name={`candidato_ir_doc_${receita.id}`}
                id={`candidato_ir_doc_${receita.id}`}
                onChange={(event) => uploadFileGrupoFamiliar(event.target.files[0], 'ir_doc', receita)}
              > 
              </input>
              </Label>
            </div>

          </Row>  
  
          ))

        }
     
        { fiador && fiador.nome &&
            <>
              <h1>Dados do Fiador</h1>
              <hr />

              <Row style={{marginBottom: '20px'}}>
              <div style={{ flex: "0 0 30%" }}>
                {fiador.nome}
              </div>

              <div style={{ flex: "1", width: '180px', textAlign: 'center' }}>
                <Label htmlFor={`candidato_fiadores_doc_identificacao_${fiador.id}`}>Doc. de identificação
                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                  <Icon path={mdiCloudUpload} size="32px" color={isImage(fiador.doc_identificacao)}/> 
                </span>
                <input 
                  style={{ disabled: 'hidden', visibility: 'hidden'}}
                  type="file"
                  accept="application/pdf, image/png, image/jpg" 
                  name={`candidato_fiadores_doc_identificacao_${fiador.id}`}
                  id={`candidato_fiadores_doc_identificacao_${fiador.id}`}
                  onChange={(event) => uploadFileFiador(event.target.files[0], 'doc_identificacao', fiador)}
                > 
                </input>
                </Label>
              </div>

              <div style={{ flex: "1", width: '180px', textAlign: 'center' }}>
                <Label htmlFor={`ccandidato_fiadores_residencia_doc_${fiador.id}`}>Comp. de Residência
                <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                  <Icon path={mdiCloudUpload} size="32px" color={isImage(fiador.residencia_doc)}/> 
                </span>
                <input 
                  style={{ disabled: 'hidden', visibility: 'hidden'}}
                  type="file"
                  accept="application/pdf, image/png, image/jpg" 
                  name={`ccandidato_fiadores_residencia_doc_${fiador.id}`}
                  id={`ccandidato_fiadores_residencia_doc_${fiador.id}`}
                  onChange={(event) => uploadFileFiador(event.target.files[0], 'residencia_doc', fiador)}
                > 
                </input>
                </Label>
              </div>
    
              <div style={{ flex: "1", width: '180px' }}>
              <Label htmlFor={`candidato_fiadores_ganhos_mensais_doc_${fiador.id}`}>Comp. Ganhos Mensais
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <Icon path={mdiCloudUpload} size="32px" color={isImage(fiador.ganhos_mensais_doc)}/> 
              </span>
              <input 
                style={{ disabled: 'hidden', visibility: 'hidden'}}
                type="file"
                accept="application/pdf, image/png, image/jpg" 
                name={`candidato_fiadores_ganhos_mensais_doc_${fiador.id}`}
                id={`candidato_fiadores_ganhos_mensais_doc_${fiador.id}`}
                onChange={(event) => uploadFileFiador(event.target.files[0], 'ganhos_mensais_doc', fiador)}
              > 
              </input>
              </Label>
            </div>

            <div style={{ flex: "1", width: '120px', marginLeft: '16px' }}>
            <Label htmlFor={`candidato_fiadores_ir_doc_${fiador.id}`}>Declaração IR
              <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}}>
                <Icon path={mdiCloudUpload} size="32px" color={isImage(fiador.ir_doc)}/> 
              </span>
              <input 
                style={{ disabled: 'hidden', visibility: 'hidden'}}
                type="file"
                accept="application/pdf, image/png, image/jpg" 
                name={`candidato_fiadores_ir_doc_${fiador.id}`}
                id={`candidato_fiadores_ir_doc_${fiador.id}`}
                onChange={(event) => uploadFileFiador(event.target.files[0], 'ir_doc', fiador)}
              > 
              </input>
              </Label>
            </div>


            </Row>  


            </>
        
        }

      </Content>
    </>
  )
}

export default Documentos;
